import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {catchError, switchMap, tap} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {AlertsService, sleep} from 'nb-form';
import {LoadingService} from '../../../../shared/service/loading/loading.service';
import {throwError} from 'rxjs';
import {getErrorMessage} from '../../../../shared/error/error-message.function';
import {fetchApiUrl, sendPost} from '../../../../shared/function/api-url.function';

@Injectable({
    providedIn: 'root'
})
export class VehicleExportProtocolService {

    constructor(private http: HttpClient, private loadingService: LoadingService, private alertsService: AlertsService) {
    }

    getUrl(vehicleId) {
        return '/v1/fleet/vehicle/' + vehicleId + '/protocol-export';
    }

    store(vehicleId: number) {
        return sendPost(this.http, this.getUrl(vehicleId), {
            vehicle: vehicleId
        });
    }

    download(vehicleId: number) {
        const headers = new HttpHeaders();
        headers.set('Accept', 'application/zip');
        return fetchApiUrl().pipe(
            switchMap(apiUrl => this.http.get(apiUrl + this.getUrl(vehicleId), {
                headers,
                responseType: 'blob'
            })),
            tap(blob => saveAs(blob, vehicleId + '-vehicle-export.zip'))
        );
    }

    export(vehicleId: number) {
        this.loadingService.show();
        return this.store(vehicleId).pipe(
            switchMap(() => sleep(1000)),
            switchMap(() => this.download(vehicleId)),
            tap(() => this.loadingService.hide()),
            catchError((err: HttpErrorResponse) => {
                this.loadingService.hide();
                this.alertsService.toast({duration: 2000, message: getErrorMessage(err)});
                return throwError(err);
            })
        );
    }

}
