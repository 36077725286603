import { FleetVehicleIssue } from '../../timeline/interface/FleetVehicleIssue';
import { Vehicle } from './vehicle.model';
import { VehiclesPage } from '../component/vehicles/vehicles.page';

export class VehicleSearch {

    currentFilters = {};

    fields = {
        id: 'exact',
        fleetStatus: 'dropDown',
        producer: 'dropDown',
        vehicleClass: 'dropDown',
        vehicleModel: 'dropDown',
        engine: 'dropDown',
        fuel: 'dropDown',
        fleetLocation: 'searchName',
        fleetGroup: 'searchName',
        fleetVehicleIssue: 'searchArrayName',
        pax: 'exact',
        licence: 'input',
        carNumber: 'input',
        chassis: 'input',
        color: 'input',
        equipment: 'input',
        kilowatt: 'greaterThan'
    };

    filters = {
        exact: (data: any, value) => {
            if (value == '') {
                return true;
            }
            return (data.toString() === value);
        },
        dropDown: (data: any, value) => {
            if (!Array.isArray(value)) {
                return true;
            }
            if (Array.isArray(value)) {
                return (value.length < 1 || value.includes(data));
            }
            return false;
        },
        input: (data: any, value) => {
            return (value.length < 1 || data.toLowerCase().indexOf(value.toString().toLowerCase()) !== -1);
        },
        searchName: (data: any, value) => {
            if (Array.isArray(value)) {
                return (data && value.indexOf(data.name) !== -1);
            }
            return false;
        },
        searchArrayName: (data: any[], value) => {
            if (!Array.isArray(value)) {
                return true;
            }

            // Wenn ein Array übergeben wird, dann ist das Feld FleetVehicleIssue - Dort muss nach dem Namen gefiltert werden
            if (Array.isArray(data)) {
                const found = data.find((issue: FleetVehicleIssue) => {
                    return (value.indexOf(issue.fleetIssue.name) !== -1);
                });
                return (found);
            }
            return false;
        },
        greaterThan: (data: number, value) => {
            const searchVal = parseInt(value);
            if (isNaN(searchVal)) {
                return true;
            }
            return (searchVal <= data);
        }
    };

     /**
     *
     * applies filters from a stringified JSON-object
     * combination (returns false if one filter does not apply)
     *
     */
    private customFilterPredicate(data: Vehicle) {
        const filterKeys = Object.keys(this.currentFilters);
        if (filterKeys.length < 1) {
            return true;
        }

        const results = filterKeys.map((key) => {
            if (key === 'equipment') {
                const tempData = data[key] + ',' + data.specialEquipment;
                return this.filters.input(tempData, this.currentFilters[key]);
            }

            if (data[key] !== null) {
                return this.filters[this.fields[key]](data[key], this.currentFilters[key]);
            }
            return false;
        });

        return results.reduce((a: Boolean, b: Boolean) => {
            return (a && b);
        });
    }

    apply(vehicles: Vehicle[]): Vehicle[] {
        return vehicles.filter((vehicle: Vehicle) => this.customFilterPredicate(vehicle));
    }

    clearAll() {
        this.currentFilters = {};
    }

    clearFilter(fieldName: string) {
        delete this.currentFilters[fieldName];
    }

    areFiltersApplied() {
        const appliedKeys = Object.keys(this.currentFilters);
        return (appliedKeys.length > 0);
    }


}
