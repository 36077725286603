import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {Protocol} from '../model/protocol.model';
import {FormPage, NbDocument} from 'nb-form';
import {Vehicle} from '../../vehicles/model/vehicle.model';
import {State} from '../../vehicles/model/state.model';
import {Observable} from 'rxjs';
import {sendGet} from '../../../shared/function/api-url.function';

@Injectable({
    providedIn: 'root'
})
export class ProtocolDownloadService {

    constructor(private http: HttpClient) {
    }

    create(vehicle: Vehicle, state: State): Observable<{ data: any, schemata: FormPage[] }> {
        return sendGet(this.http, '/v1/fleet/vehicle/' + vehicle.id + '/protocol').pipe(
            map((response: any) => response.data as { data: any, schemata: FormPage[] }),
            map(protocol => {
                protocol.data.vehicle = vehicle;
                protocol.data.state = state;
                protocol.data.status = state;
                return protocol;
            })
        );
    }

    async show(id: number): Promise<Protocol> {
        return await sendGet(this.http, '/v1/fleet/protocol/' + id).pipe(
            map((response: any) => response.data),
            map((data: any) => {
                const protocol = new Protocol();
                protocol.data = data.data;
                protocol.schema = data.schemata;
                protocol.readonly = true;
                protocol.id = data.id;
                protocol.files = data.files;
                return protocol;
            })
        ).toPromise();
    }

    async showFile(id: number): Promise<NbDocument> {
        return await sendGet(this.http, '/v1/fleet/protocol/file/' + id).pipe(
            map((response: any) => {
                console.log(response);
                return {
                    name: response.name,
                    data: response.data
                } as NbDocument;
            })
        ).toPromise();
    }
}
