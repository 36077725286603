import {Injectable} from '@angular/core';
import {Vehicle} from '../../model/vehicle.model';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {LoadingService} from '../../../../shared/service/loading/loading.service';
import {ToastService} from 'src/app/module/shared/service/toast/toast.service';
import {VehiclesService} from '../vehicles/vehicles.service';
import {VehicleForm} from '../../model/vehicle-form.model';
import {reportError} from '../../../../shared/error/sentry.error-handler';
import {sendDelete, sendPatch, sendPost} from '../../../../shared/function/api-url.function';

@Injectable({
    providedIn: 'root'
})
export class VehicleService {

    constructor(
        private http: HttpClient,
        private loading: LoadingService,
        private toast: ToastService,
        private vehicles: VehiclesService
    ) { }

    parseVehicleData(vehicle: Vehicle): VehicleForm {
        return {
            chassis: vehicle.chassis,
            color: vehicle.color,
            vehicleClass: vehicle.vehicleClass,
            comment: '',
            engine: vehicle.engine,
            fleetLocationId: !!vehicle.fleetLocation ? vehicle.fleetLocation.id : undefined,
            fleetGroupId: !!vehicle.fleetGroup ? vehicle.fleetGroup.id : undefined,
            fuel: vehicle.fuel,
            kilowatt: vehicle.kilowatt,
            licence: vehicle.licence,
            pax: vehicle.pax,
            producer: vehicle.producer,
            vehicleModel: vehicle.vehicleModel,
            carNumber: vehicle.carNumber
        } as VehicleForm;
    }

    async store(vehicle: Vehicle): Promise<boolean> {
        const url = '/v1/fleet/vehicle';
        const response = await sendPost(this.http, url, {
            vehicle
        }).toPromise()
            .catch(async (e: HttpErrorResponse) => {
                await this.showErrorMessage(e);
                return null;
            }) as any;

        if (!response) {
            return false;
        }

        this.vehicles.add(response.data);
        await this.toast.success('Eintrag erfolgreich gespeichert');
        return true;
    }

    async update(vehicle: VehicleForm, idVehicle: number): Promise<boolean> {
        const url = '/v1/fleet/vehicle/' + idVehicle;
        const response = await sendPatch(this.http, url, {
            vehicle
        }).toPromise() as any;

        this.vehicles.set(response.data as Vehicle);
        return true;
    }

    async showErrorMessage(e: HttpErrorResponse) {
        if (e.status === 400) {
            await this.toast.error('Bitte überprüfen Sie Ihre Eingaben');
            return;
        }

        if (e.status === 500) {
            await this.toast.error('Fehler bei der Anfrage, bitte versuchen Sie es später erneut');
        }
        reportError(e);
    }

    async delete(vehicle: Vehicle) {
        const url = '/v1/fleet/vehicle/' + vehicle.id;
        const response = await sendDelete(this.http, url).toPromise()
            .catch(async (e: HttpErrorResponse) => {
                console.error('DeleteError: ', e);
                return e;
            }) as any;

        if (response instanceof HttpErrorResponse) {
            await this.loading.hide();
            return false;
        }

        await this.loading.hide();
        this.vehicles.remove(vehicle.id);
        return true;
    }

}
