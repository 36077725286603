import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {VehicleDamageModel} from '../../model/vehicle-damage.model';
import {map} from 'rxjs/operators';
import {sendGet} from '../../../../shared/function/api-url.function';

@Injectable({
    providedIn: 'root'
})
export class VehicleDamageService {

    constructor(private http: HttpClient) {
    }

    getUrl(vehicleId: number) {
        return '/v1/fleet/vehicle/' + vehicleId + '/damages';
    }

    loadList(vehicleId: number): Observable<VehicleDamageModel[]> {
        return sendGet(this.http, this.getUrl(vehicleId) + '?limit=99&orderDesc=createdAt').pipe(
            map((response: any) => response.data as VehicleDamageModel[])
        );
    }

}
