import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { LocationsService } from '../../../../locations/service/locations.service';
import { Location } from '../../../../locations/model/location.model';
import { LoadingService } from 'src/app/module/shared/service/loading/loading.service';
import { Form, FormPage, NbFormValidatorService } from 'nb-form';
import { VehicleService } from '../../../service/vehicle/vehicle.service';
import { Vehicle } from '../../../model/vehicle.model';
import { ToastService } from 'src/app/module/shared/service/toast/toast.service';
import { environment } from '../../../../../../../environments/environment';
import { HttpErrorResponse } from '@angular/common/http';
import {reportError} from '../../../../../shared/error/sentry.error-handler';


@Component({
    selector: 'app-vehicle-modal',
    templateUrl: './vehicle-modal.html',
    styleUrls: ['./vehicle-modal.scss'],
})
export class VehicleModalComponent implements OnInit {

    title: string;

    locations: Location[];

    form: Form;

    vehicle: Vehicle = null;

    constructor(
        private vehicleService: VehicleService,
        private modalCtrl: ModalController,
        private locationService: LocationsService,
        private loading: LoadingService,
        private formValidator: NbFormValidatorService,
        private toast: ToastService
    ) { }

    async ngOnInit() {
        await this.loading.show();
        this.locations = await this.locationService.load();
        this.form = new Form();
        this.form.schema = this.getSchema(this.locations);

        if (this.vehicle) {
            this.form.data = this.vehicleService.parseVehicleData(this.vehicle);
        } else {
            this.form.data = {};
        }

        await this.loading.hide();
    }

    async saveVehicle() {
        if (this.formValidator.validate() === false) {
            await this.toast.error('Bitte überprüfen Sie Ihre Eingaben');
            return;
        }
        await this.loading.show();

        let result = false;
        if (this.vehicle) {
            result = await this.update();
        } else {
            result = await this.create();
        }

        await this.loading.hide();
        if (result) {
            this.toast.success('Erfolgreich gespeichert');
            this.closeModal();
        }
    }

    async create(): Promise<boolean> {
        return await this.vehicleService.store(this.form.data);
    }

    async update(): Promise<boolean> {
        const result = await this.vehicleService.update(this.form.data, this.vehicle.id)
            .catch(async (e: HttpErrorResponse) => {
                reportError(e);
                await this.loading.hide();
                await this.vehicleService.showErrorMessage(e);
                return false;
            });
        return !!result;
    }

    closeModal() {
        this.modalCtrl.dismiss();
    }

    private getLocationOptions(locations: Location[]) {
        const options = [
            {
                key: null,
                value: 'Kein Standort'
            }
        ];

        locations.map((location: Location) => {
            options.push({ key: location.id, value: location.name });
        });
        return options;
    }

    private getSchema(locations: Location[]): FormPage[] {
        const locationOptions = this.getLocationOptions(locations);
        return [
            {
                ber_reiter_id: 'vehicle',
                elements: [
                    {
                        id: '0-0',
                        type: 'textarea',
                        label: 'Kennzeichen',
                        model: 'licence'
                    },
                    {
                        id: '0-1',
                        type: 'textarea',
                        label: 'Wagen-Nr.',
                        model: 'carNumber'
                    },
                    {
                        id: '0-2',
                        type: 'textarea',
                        label: 'Fahrgestell-Nr.',
                        model: 'chassis'
                    },
                    {
                        id: '0-3',
                        type: 'textarea',
                        label: 'Hersteller',
                        model: 'producer',
                        required: true
                    },
                    {
                        id: '0-4',
                        type: 'textarea',
                        label: 'Fahrzeug-Typ',
                        model: 'vehicleClass',
                        required: true
                    },
                    {
                        id: '0-5',
                        type: 'textarea',
                        label: 'Fahrzeug-Modell',
                        model: 'vehicleModel',
                        required: true
                    },
                    {
                        id: '0-6',
                        type: 'number',
                        label: 'Pax',
                        model: 'pax',
                        required: true
                    },
                    {
                        id: '0-7',
                        type: 'textarea',
                        label: 'Motor',
                        model: 'engine'
                    },
                    {
                        id: '0-8',
                        type: 'number',
                        label: 'Kilowatt',
                        model: 'kilowatt'
                    },
                    {
                        id: '0-9',
                        type: 'textarea',
                        label: 'Treibstoff',
                        model: 'fuel'
                    },
                    {
                        id: '0-10',
                        type: 'textarea',
                        label: 'Farbe',
                        model: 'color'
                    },
                    {
                        id: '0-11',
                        type: 'nb-dropdown',
                        label: 'Standort',
                        model: 'fleetLocationId',
                        options: locationOptions
                    },
                    {
                        id: '0-12',
                        type: 'textarea',
                        label: 'Kommentar',
                        model: 'comment'
                    }
                ]
            } as FormPage
        ];
    }

    get debug() {
        return !environment.production;
    }
}
