import {Component, Input} from '@angular/core';
import {Vehicle} from '../../../model/vehicle.model';
import {StateService} from '../../../service/state/state.service';

@Component({
    selector: 'app-vehicle-details',
    templateUrl: './vehicle-details.component.html',
    styleUrls: ['./vehicle-details.component.scss'],
})
export class VehicleDetailsComponent {

    @Input() vehicle: Vehicle;

    constructor(public states: StateService) {
    }

}
