import {Injectable} from '@angular/core';
import {LoadingController} from '@ionic/angular';

@Injectable({
    providedIn: 'root'
})
export class LoadingService {

    private spinner: HTMLIonLoadingElement;

    private _loading = false;

    constructor(private loadingCtrl: LoadingController) {
    }

    async show() {
        this._loading = true;
        this.spinner = await this.loadingCtrl.create({
            message: 'Bitte warten ...'
        });
        return await this.spinner.present();
    }

    async hide() {
        await this.spinner.dismiss();
        this._loading = false;
    }

    get loading() {
        return this._loading;
    }

    setMessage(message: string) {
        this.spinner.message = message;
        console.log(message);
    }
}
