import {ErrorHandler, Injectable} from '@angular/core';

import * as Sentry from '@sentry/browser';
import {environment} from '../../../../environments/environment';
import {throwError} from 'rxjs';

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
    constructor() {
    }

    handleError(error) {
        reportError(error);
        return throwError(error);
    }
}

export function reportErrorMessage(message: string, context?: any) {
    console.error(message, context);
    console.error('Error context', context);
    if (!environment.sentryDsn) {
        return;
    }
    try {
        Sentry.captureMessage(message);
        if (context) {
            Sentry.setContext('context', context);
        }
    } catch (e) {
    }

}

export function reportError(error: any, context?: any) {
    console.error(error, context);
    console.error('Error context', context);
    if (!environment.sentryDsn) {
        return;
    }
    try {
        Sentry.captureException(error.originalError || error);
        if (context) {
            Sentry.setContext('context', context);
        }
    } catch (e) {
    }
}
