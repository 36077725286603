import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {saveAs} from 'file-saver';
import {catchError, switchMap} from 'rxjs/operators';
import {throwError} from 'rxjs';
import {AlertsService} from 'nb-form';
import {fetchApiUrl, sendPost} from '../../../shared/function/api-url.function';

@Injectable({
    providedIn: 'root'
})
export class ProtocolExportService {

    constructor(
        private http: HttpClient,
        private alertsService: AlertsService
    ) {

    }

    get headers() {
        const headers = new HttpHeaders();
        return headers.set('Accept', 'application/pdf');
    }

    async export(protocolId: number) {
        const result = await this.downloadFile(protocolId).pipe(
            catchError((err: HttpErrorResponse) => {
                if (err && err.status === 404) {
                    return this.triggerExport(protocolId).pipe(
                        switchMap(() => this.downloadFile(protocolId))
                    );
                }
                return throwError(err);
            })
        ).toPromise().catch(e => e);
        if (result instanceof HttpErrorResponse) {
            this.alertsService.toast({duration: 2000, message: result.message});
            return;
        }
        saveAs(result, 'protocol-' + protocolId + '.pdf');
    }

    downloadFile(protocolId: number) {
        return fetchApiUrl().pipe(
            switchMap(apiUrl => this.http.get(apiUrl + '/v1/fleet/protocol-pdf/' + protocolId, {
                headers: this.headers,
                responseType: 'blob'
            }))
        );
    }

    triggerExport(protocolId: number) {
        return sendPost(this.http, '/v1/fleet/protocol-pdf/' + protocolId, {});
    }
}
