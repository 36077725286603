import {Injectable} from '@angular/core';
import {DocumentAdapter} from 'nb-form/lib/service/document.adapter.interface';
import {NbDocument} from 'nb-form/lib/model/document.model';
import {Storage} from '@ionic/storage';
import {NbFormDataService, NbFormService} from 'nb-form';
import {FileModel} from '../model/file.model';
import {ProtocolDownloadService} from './protocol-download.service';
import {Protocol, ProtocolFileRef} from '../model/protocol.model';
import {LoadingService} from '../../../shared/service/loading/loading.service';
import {from} from 'rxjs';
import {map} from 'rxjs/operators';
import {reportError} from '../../../shared/error/sentry.error-handler';

@Injectable({
    providedIn: 'root'
})
export class FileAdapterService implements DocumentAdapter {

    constructor(
        private storage: Storage,
        private nbform: NbFormService,
        private data: NbFormDataService,
        private downloadService: ProtocolDownloadService,
        private loading: LoadingService
    ) {
    }

    write(idElement: string, files: NbDocument[]): Promise<boolean> {
        return this.writeToVehicle(this.data._read('vehicle.id', 0), idElement, files);
    }

    async writeToVehicle(vehicleId: number, idElement: string, files: NbDocument[]): Promise<boolean> {
        const _files = files.map((f, index) => {
            const file = f as any as FileModel;
            file.formElement = idElement;
            file.index = index;
            return file as any as NbDocument;
        });
        await this.storage.set(this.getStorageKey(idElement, vehicleId), _files);
        return true;
    }

    async read(idElement: string, index: number): Promise<NbDocument | null> {
        await this.loading.show();
        if (this.nbform.definition.readonly) {
            const fileId = this.findFileId(idElement, index);
            if (!fileId) {
                await this.loading.hide();
                return null;
            }
            const file = await this.download(fileId);
            await this.loading.hide();
            return file;
        }
        const result = await this.loadFromStorage(idElement, index);
        await this.loading.hide();
        return result;
    }

    private async loadFromStorage(idElement: string, index: number) {
        const files = await this.storage.get(this.getStorageKey(idElement)) as NbDocument[];
        if (!!files && typeof files === 'object' && files[index]) {
            await this.loading.hide();
            return files[index];
        }
        return null;
    }

    private async download(fileId: number) {
        return await this.downloadService.showFile(fileId).catch(e => {
            reportError(e);
            return null;
        });
    }

    /**
     * Lädt alle Dateien eines Protokolls herunter und gibt sie gruppiert nach FormElement zurück
     */
    async downloadAll(protocol: Protocol): Promise<{ [elementId: string]: FileModel[] }> {
        if (!Array.isArray(protocol.files)) {
            return {};
        }
        const fileRefs = protocol.files;
        const fileMap = {};
        for (const fileRef of fileRefs) {
            const count = fileRefs.indexOf(fileRef) + 1;
            this.loading.setMessage('Lade Bilder herunter ... ' + count + ' von ' + fileRefs.length);
            const nbDoc = await this.download(fileRef.id);
            if (!nbDoc) {
                continue;
            }
            this.addToFileMap(fileMap, fileRef, nbDoc.data);
        }
        return fileMap;
    }

    private addToFileMap(fileMap: {[elementId: string]: FileModel[]}, fileRef: ProtocolFileRef, fileData: string) {
        if (!Array.isArray(fileMap[fileRef.formElement])) {
            fileMap[fileRef.formElement] = [];
        }
        const file = {
            name: fileRef.name,
            data: fileData,
            index: fileMap[fileRef.formElement].length,
            formElement: fileRef.formElement
        } as FileModel;
        fileMap[fileRef.formElement].push(file);
    }

    private findFileId(idElement: string, index: number) {
        if (!this.nbform.definition || !this.nbform.definition.id || !this.nbform.definition.readonly) {
            return null;
        }
        const protocol = this.nbform.definition as Protocol;
        if (!Array.isArray(protocol.files)) {
            return null;
        }
        // find value from element
        const formValue = this.data.readByElementId(idElement);
        if (!Array.isArray(formValue) || !formValue[index]) {
            return null;
        }
        const fileName = formValue[index].name;
        // find value in Protocol files received from server
        const file = protocol.files.find(f => f.name === fileName && f.formElement === idElement);
        if (!file) {
            return null;
        }
        return file.id;
    }

    private getStorageKey(idElement: string, vehicleId?: number): string {
        if (!vehicleId) {
            vehicleId = this.data._read('vehicle.id', 0);
        }
        return getFileKey(vehicleId, idElement);
    }

    async cleanStorage(vehicleId: number) {
        const keys = (await this.storage.keys()).filter(k => k.indexOf('nf.file.' + vehicleId + '.') === 0);
        for (const key of keys) {
            await this.storage.remove(key);
        }
    }

    async getAllFiles(vehicleId: number): Promise<{ [idElement: string]: FileModel[] }> {
        const keys = await from(this.storage.keys()).pipe(
            map((v: string[]) => v.filter(key => key.indexOf('nf.file.' + vehicleId + '.') === 0))
        ).toPromise();
        if (!Array.isArray(keys)) {
            return {};
        }
        const files = {};
        for (const key of keys) {
            files[key.replace('nf.file.' + vehicleId + '.', '')] = await this.storage.get(key);
        }
        return files;
    }

}

export function getFileKey(vehicleId: number, elementId: string) {
    return 'nf.file.' + vehicleId + '.' + elementId;
}



