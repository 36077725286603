import {Directive, ElementRef, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef} from '@angular/core';
import {Subscription} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {LoginService} from '../../page/account/service/login/login.service';
import {AccService} from '../service/acc.service';
import {AclModel} from '../model/acl.model';

@Directive({
    selector: '[appAccRoute]'
})
export class AccRouteDirective implements OnInit, OnDestroy {

    @Input() appAccRouteMethod: string;

    loginSub: Subscription;

    private acl: AclModel;

    private hasView: boolean;

    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
        private accService: AccService,
        private loginService: LoginService
    ) {
    }

    @Input() set appAccRoute(acl: string | AclModel) {
        if (typeof acl === 'string') {
            this.acl = {
                route: acl,
                method: 'GET'
            };
        } else {
            this.acl = acl;
        }
        this.init();
    }


    ngOnInit() {
        this.loginSub = this.loginService.login.subscribe(() => this.init());
    }

    ngOnDestroy() {
        if (this.loginSub) {
            this.loginSub.unsubscribe();
        }
    }

    get condition() {
        return environment.ignoreAcc
            || !!this.acl
            && this.accService.isRouteAllowed(this.acl.route, this.acl.method);
    }

    init() {
        if (!this.hasView && this.condition) {
            this.show();
        } else if (!this.condition && this.hasView) {
            this.hide();
        }
    }

    show() {
        this.viewContainer.createEmbeddedView(this.templateRef);
        this.hasView = true;
    }

    hide() {
        this.viewContainer.clear();
        this.hasView = false;
    }
}
