import {Component, OnDestroy, OnInit, ViewChild, ChangeDetectionStrategy, ElementRef} from '@angular/core';
import {StateService} from '../../service/state/state.service';
import {LoadingService} from '../../../../shared/service/loading/loading.service';
import {ToastService} from '../../../../shared/service/toast/toast.service';
import {MatTableDataSource} from '@angular/material/table';
import {Vehicle} from '../../model/vehicle.model';
import {VehiclesService} from '../../service/vehicles/vehicles.service';
import {MatSort} from '@angular/material/sort';
import {FormControl, FormGroup} from '@angular/forms';
import {SelectionModel} from '@angular/cdk/collections';
import {Subscription, merge} from 'rxjs';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ModalController} from '@ionic/angular';
import {VehicleModalComponent} from '../vehicle/vehicle-modal/vehicle-modal';
import {VehicleService} from '../../service/vehicle/vehicle.service';
import {AlertsService} from 'nb-form';
import {VehicleIssueModalComponent} from '../../../issues/component/vehicle-issue-modal/vehicle-issue-modal.component';
import {FormType} from '../../../../shared/model/form-type';
import * as _moment from 'moment';
import {ExportService} from '../../service/export/export.service';
import {AllocationTypes} from '../../model/allocation-types';
import {AllocationModalComponent} from '../vehicle/allocation-modal/allocation-modal';
import {VehicleSearch} from '../../model/vehicle-search';
import {ActivatedRoute} from '@angular/router';
import {VehicleExportProtocolService} from '../../service/export/vehicle-export-protocol.service';

const moment = _moment;

@Component({
    selector: 'app-vehicle-table',
    templateUrl: './table.component.html',
    styleUrls: ['./table.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class VehiclesTableComponent implements OnInit, OnDestroy {

    @ViewChild(MatSort, {static: true}) sort: MatSort;
    @ViewChild('tableWrapper', {read: ElementRef})
    wrapper;

    displayedColumns = ['actions', 'selected', 'id', 'fleetStatus', 'licence', 'carNumber', 'chassis', 'fleetVehicleIssues', 'fleetGroup',
        'producer', 'vehicleClass', 'vehicleModel', 'pax', 'engine', 'kilowatt', 'fuel', 'color', 'equipment', 'specialEquipment',
        'fleetLocation'];
    // header for special Equipment is not displayed
    displayedColumnsHeadline = this.displayedColumns.filter(cell => cell !== 'specialEquipment');

    dataSource = new MatTableDataSource<Vehicle>();

    filterForm = new FormGroup({
        id: new FormControl(''),
        licence: new FormControl(''),
        carNumber: new FormControl(''),
        chassis: new FormControl(''),
        producer: new FormControl(''),
        vehicleClass: new FormControl(''),
        vehicleModel: new FormControl(''),
        pax: new FormControl(''),
        engine: new FormControl(''),
        kilowatt: new FormControl(''),
        fuel: new FormControl(''),
        color: new FormControl(''),
        equipment: new FormControl(''),
        specialEquipment: new FormControl(''),
        fleetStatus: new FormControl(''),
        fleetLocation: new FormControl(''),
        fleetVehicleIssue: new FormControl(''),
        fleetGroup: new FormControl('')
    });

    selection = new SelectionModel<any>(true, []);

    // Subscriptions
    private currentSnackBarSub: Subscription;
    private changeSubscription: Subscription;
    private routeChangeSubscription: Subscription;

    // Arrays with Select-Options
    fuels = [];
    vehicleClasses = [];
    producers = [];
    vehicleModels = [];
    engines = [];
    locations = [];
    issues = [];
    groups = [];

    allVehicles: Vehicle[] = [];
    vehiclesCount: number;

    vehicleSearch = new VehicleSearch();

    private page = 1;
    private pageSize = 30;
    private maxPage: number = null;
    private disableScroll = false;
    private displaySize: number;

    constructor(
        public vehicles: VehiclesService,
        public states: StateService,
        private loading: LoadingService,
        private toast: ToastService,
        private snackBar: MatSnackBar,
        private modalCtrl: ModalController,
        private vehicle: VehicleService,
        private alert: AlertsService,
        private csvExport: ExportService,
        private route: ActivatedRoute,
        private vehicleExportProtocolService: VehicleExportProtocolService
    ) {
    }

    async ngOnInit() {
        await this.loading.show();

        this.initSubscriptions();

        await this.loading.hide();
    }

    /**
     * Load and prepare Data onInit or after Logout due to expired token
     */
    private async loadOnInit() {
        const resultStates = await this.states.loadList().catch(e => {
            this.toast.httpError(e);
            return e;
        });
        if (!Array.isArray(resultStates)) {
            await this.loading.hide();
            return;
        }

        this.allVehicles = await this.vehicles.loadList().catch(e => {
            this.toast.httpError(e);
            return e;
        });

        this.maxPage = this.allVehicles.length / this.pageSize;
        this.vehiclesCount = this.allVehicles.length;
        this.displaySize = this.pageSize;
        this.getSelectOptions();
        if (!Array.isArray(this.allVehicles)) {
            await this.loading.hide();
            return;
        }

        this.replaceSort();
        this.dataSource.sort = this.sort;
        this.dataSource.data = this.paginate(1);
    }

    ngOnDestroy(): void {
        this.unsubscribe();
    }

    private unsubscribe() {
        if (this.currentSnackBarSub) {
            this.currentSnackBarSub.unsubscribe();
        }
        if (this.changeSubscription) {
            this.changeSubscription.unsubscribe();
        }
    }

    replaceSort() {
        this.dataSource.sortData = (data: Vehicle[], sort: MatSort) => {
            const direction = sort.direction;
            const sortColumn = sort.active;
            const objectColumns = [
                'fleetGroup', 'fleetLocation'
            ];

            if (direction === '' || !sortColumn) {
                return data;
            }

            let tempData = this.filter(this.allVehicles);
            if (objectColumns.includes(sortColumn)) {
                tempData = tempData.sort((a, b) => this.sortByName(a, b, sortColumn));
            } else {
                tempData = tempData.sort((a, b) => this.sortDefault(a, b, sortColumn));
            }

            if (direction === 'desc') {
                tempData.reverse();
            }
            return tempData.slice(0, this.displaySize);
        };
    }

    sortByName(a: Vehicle, b: Vehicle, column: string): number {
        const aIsSortable = (a[column] !== null && typeof a[column] === 'object');
        const bIsSortable = (b[column] !== null && typeof b[column] === 'object');

        if (!aIsSortable && !bIsSortable) {
            return 0;
        }

        if (!aIsSortable || !bIsSortable) {
            return (aIsSortable) ? 1 : -1;
        }

        if (a[column].name === b[column].name) {
            return 0;
        }
        return (a[column].name > b[column].name) ? 1 : -1;
    }

    sortDefault(a: Vehicle, b: Vehicle, column: string): number {
        return (a[column] > b[column]) ? 1 : -1;
    }

    private initSubscriptions() {
        this.changeSubscription = merge(this.vehicles.vehiclesAdded, this.vehicles.vehiclesChanged, this.vehicles.vehiclesRemoved)
            .subscribe(async (vehicles: Vehicle[]) => {
                this.disableScroll = true;
                await this.updatePage();
                this.disableScroll = false;
            });
        this.routeChangeSubscription = this.route.params.subscribe(async (params: any) => {
            if (this.allVehicles.length < 1) {
                await this.loading.show();
                await this.loadOnInit();
                await this.loading.hide();
            }
        });
    }

    async onTableScroll($event) {
        if (this.disableScroll) {
            return;
        }
        const event = $event;
        const winHeight = window.innerHeight;

        const limit = Math.max(document.body.scrollHeight, document.body.offsetHeight,
            event.target.clientHeight, event.target.scrollHeight, event.target.offsetHeight);

        const scrollTopMax = limit - winHeight;
        const offset = event.target.scrollTop;

        if (scrollTopMax <= offset && this.page < this.maxPage && this.disableScroll === false) {
            this.disableScroll = true;
            await this.loading.show();
            setTimeout(async () => {
                this.page++;
                this.dataSource.data = this.dataSource.data.concat(this.paginate(this.page));
            }, 400);
            await this.loading.hide();
            this.disableScroll = false;
        }
    }

    paginate(page: number) {
        this.page = page;
        const results = this.filter(this.allVehicles);
        // infinite scroll
        this.displaySize = page * this.pageSize;
        return results.slice((page - 1) * this.pageSize, this.displaySize);
    }

    filter(vehicles: Vehicle[]): Vehicle[] {
        const results = this.vehicleSearch.apply(vehicles);
        this.maxPage = results.length / this.pageSize;
        return results;
    }

    async updatePage() {
        this.allVehicles = this.vehicles.vehicles;
        this.allVehicles.sort((a, b) => {
            return (a.licence > b.licence) ? 1 : -1;
        });
        this.maxPage = this.allVehicles.length / this.pageSize;
        const page = this.page;

        let vehicles = [];
        for (let i = 1; i <= page; i++) {
            vehicles = vehicles.concat(this.paginate(i));
        }
        this.dataSource.data = vehicles;
    }

    /**
     * get possible options for selects based on data
     */
    private getSelectOptions() {
        this.allVehicles.map((v) => {
            if (!this.fuels.includes(v.fuel)) {
                this.fuels.push(v.fuel);
            }
            if (!this.vehicleClasses.includes(v.vehicleClass)) {
                this.vehicleClasses.push(v.vehicleClass);
            }
            if (!this.producers.includes(v.producer)) {
                this.producers.push(v.producer);
            }
            if (!this.vehicleModels.includes(v.vehicleModel)) {
                this.vehicleModels.push(v.vehicleModel);
            }
            if (!this.engines.includes(v.engine)) {
                this.engines.push(v.engine);
            }
            if (!!v.fleetLocation && !this.locations.includes(v.fleetLocation.name)) {
                this.locations.push(v.fleetLocation.name);
            }
            if (!!v.fleetGroup && !this.groups.includes(v.fleetGroup.name)) {
                this.groups.push(v.fleetGroup.name);
            }
            for (const vi of v.fleetVehicleIssue) {
                if (!this.issues.includes(vi.fleetIssue.name)) {
                    this.issues.push(vi.fleetIssue.name);
                }
            }
        });
    }

    async clickUpdate() {
        this.selection.clear();
        await this.states.loadFromServer();
        const oldVehicles = this.allVehicles;
        await this.loading.show();

        this.allVehicles = await this.vehicles.loadFromServer().catch(e => {
            this.toast.httpError(e);
            return oldVehicles;
        });
        await this.loading.hide();
        this.wrapper.nativeElement.scrollTop = 0;
        this.dataSource.data = this.paginate(1);
        await this.toast.success('Fahrzeuge aktualisiert');
    }

    /**
     * add filter-string for a column to a JSON object or load it
     */
    async applyFilter(column, value) {
        this.selection.clear();
        this.wrapper.nativeElement.scrollTop = 0;
        if (value.length > 0) {
            this.vehicleSearch.currentFilters[column] = value;
        } else {
            this.vehicleSearch.clearFilter(column);
        }

        this.dataSource.data = this.paginate(1);
    }

    /**
     * puts filter-strings from dropdown in a comma-separated string and applies it
     */
    async filterDropdown(column, values) {
        await this.loading.show();
        await this.applyFilter(column, values);
        await this.loading.hide();
    }

    clearFilters() {
        this.selection.clear();
        this.filterForm.reset();
        this.vehicleSearch.clearAll();
        this.dataSource.data = this.paginate(1);
    }

    clearInput(field) {
        this.selection.clear();
        this.filterForm.controls[field].reset();
        this.vehicleSearch.clearFilter(field);
        this.dataSource.data = this.paginate(1);
    }

    toggleAll(event) {
        // if checkbox is checked, select currently displayed data and show snackbox
        if (event.checked) {
            const vehiclesToSelect = this.filter(this.allVehicles);
            vehiclesToSelect.forEach(row => this.selection.select(row));

            const message = (vehiclesToSelect.length === this.vehiclesCount) ?
                'Alle ' + this.allVehicles.length + ' Fahrzeuge sind ausgewählt' :
                vehiclesToSelect.length + ' Fahrzeuge mit dem aktuellen Filter sind ausgewählt';

            const action = 'Schließen';
            const snackBarRef = this.snackBar.open(message, action, {
                duration: 2000,
            });

            // change selection to complete data
            this.currentSnackBarSub = snackBarRef.onAction().subscribe(() => {
                this.snackBar.dismiss();
            });
        } else {
            // remove complete selection
            this.selection.clear();
        }
    }

    get filteredVehiclesCount() {
        return this.filter(this.allVehicles).length;
    }

    isAllSelected() {
        return this.selection.selected.length === this.filteredVehiclesCount;
    }

    /**
     * @return boolean - true if the filter-object has filters set
     */
    areFiltersApplied() {
        return this.vehicleSearch.areFiltersApplied();
    }

    async addFVISelected() {
        const modal = await this.modalCtrl.create({
            component: VehicleIssueModalComponent,
            componentProps: {
                title: 'Neuen Auftrag für alle ausgewählten Fahrzeuge anlegen',
                type: FormType.New,
                vehicles: this.selection.selected
            }
        });

        modal.onDidDismiss().then((returnResult) => {
            if (returnResult.data) {
                this.selection.clear();
            }
        });
        return await modal.present();
    }

    async addFVI(vehicle) {
        const modal = await this.modalCtrl.create({
            component: VehicleIssueModalComponent,
            componentProps: {
                title: 'Neuen Auftrag anlegen',
                type: FormType.New,
                vehicle
            }
        });
        modal.onDidDismiss().then((returnResult) => {
            if (returnResult.data) {
                this.selection.clear();
            }
        });
        return await modal.present();
    }

    async editFVI(vehicleIssue, vehicle) {
        const modal = await this.modalCtrl.create({
            component: VehicleIssueModalComponent,
            componentProps: {
                title: 'Auftrag bearbeiten',
                type: FormType.Edit,
                vehicle,
                vehicleIssue,
            }
        });
        modal.onDidDismiss().then((returnResult) => {
            if (returnResult.data) {
                this.selection.clear();
            }
        });
        return await modal.present();
    }

    async addVehicle() {
        const modalRef = await this.modalCtrl.create({
            component: VehicleModalComponent,
            cssClass: 'modal-large',
            componentProps: {
                title: 'Neues Fahrzeug anlegen',
            }
        });
        return await modalRef.present();
    }

    async deleteVehicle(vehicle?: Vehicle) {
        let text = '';
        let header = '';
        let vehicles = [];

        if (vehicle) {
            vehicles.push(vehicle);
            text += 'Wollen sie das Fahrzeug ' + vehicle.licence + ' wirklich löschen?';
            header += 'Fahrzeug löschen';
        } else {
            vehicles = this.selection.selected;
            text += 'Wollen sie die ausgewählten ' + vehicles.length + ' Fahrzeuge wirklich löschen?';
            header += vehicles.length + ' Fahrzeuge löschen';
        }

        await this.alert.confirm({
            text,
            header,
            textOk: 'Ja',
            textCancel: 'Nein'
        }).then(async (result: any) => {
            this.selection.clear();
            if (result === true) {
                await this.loading.show();
                const deleteResults = await Promise.all(vehicles.map(async (vehicle: Vehicle) => {
                    return await this.vehicle.delete(vehicle);
                }));

                const deleteResult = deleteResults.reduce((a: boolean, b: boolean): boolean => {
                    return (a && b);
                });
                if (deleteResult) {
                    const countVehicles = deleteResults.length;
                    const text = (countVehicles > 1) ? 'Es wurden ' + countVehicles + ' Fahrzeuge erfolgreich entfernt' :
                        'Das Fahrzeug wurde erfolgreich entfernt';

                    await this.toast.success(text);
                    await this.updatePage();
                } else {
                    await this.toast.error('Fehler beim Löschen der Fahrzeuge');
                }
            }
        });
        await this.loading.hide();
    }

    async exportOneVehicle(vehicle: Vehicle) {
        return await this.vehicleExportProtocolService.export(vehicle.id).toPromise();
    }

    async exportVehicles() {
        const ids = this.selection.selected.map((vehicle: Vehicle) => vehicle.id);

        if (ids.length < 1) {
            this.toast.error('Keine Fahrzeuge für den Export gefunden');
            return;
        }
        await this.csvExport.startExport(ids, 'vehicle');
    }

    async setGroup(vehicle?: Vehicle) {
        let vehicles = [];
        if (vehicle) {
            vehicles = [vehicle];
        } else {
            vehicles = this.selection.selected;

        }
        const modalRef = await this.modalCtrl.create({
            component: AllocationModalComponent,
            componentProps: {
                title: 'Gruppe ändern',
                type: AllocationTypes.FleetGroup,
                vehicles
            }
        });
        modalRef.onDidDismiss().then((returnResult) => {
            if (returnResult.data) {
                this.selection.clear();
            }
        });
        await modalRef.present();
    }

    async setLocation(vehicle?: Vehicle) {
        let vehicles = [];
        if (vehicle) {
            vehicles = [vehicle];
        } else {
            vehicles = this.selection.selected;
        }
        const modalRef = await this.modalCtrl.create({
            component: AllocationModalComponent,
            componentProps: {
                title: 'Standort ändern',
                type: AllocationTypes.FleetLocation,
                vehicles
            }
        });
        modalRef.onDidDismiss().then((returnResult) => {
            if (returnResult.data) {
                this.selection.clear();
            }
        });
        await modalRef.present();

    }

}
