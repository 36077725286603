import {EventEmitter, Injectable, Output} from '@angular/core';
import {Vehicle} from '../../model/vehicle.model';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {environment} from '../../../../../../environments/environment';
import {SingleVehicleResponse, VehicleResponse} from '../../response/vehicle.response';
import {Storage} from '@ionic/storage';
import {LoadingService} from '../../../../shared/service/loading/loading.service';
import {ProtocolService} from '../../../protocol/service/protocol.service';
import {ProtocolUploadService} from '../../../protocol/service/protocol-upload.service';
import {StateService} from '../state/state.service';
import {AutoUpdate} from 'src/app/module/shared/model/autoupdate';
import {reportError} from '../../../../shared/error/sentry.error-handler';
import {sendGet} from '../../../../shared/function/api-url.function';


@Injectable({
    providedIn: 'root'
})
export class VehiclesService implements AutoUpdate {

    private maxPages = 0;

    private _limit = 25;

    private _vehicles: Vehicle[] = [];

    private _lastLoaded: Date = null;

    @Output() vehiclesAdded = new EventEmitter<Vehicle[]>();
    @Output() vehiclesRemoved = new EventEmitter<Vehicle[]>();
    @Output() vehiclesChanged = new EventEmitter<Vehicle[]>();

    constructor(
        private http: HttpClient,
        private storage: Storage,
        private protocols: ProtocolService,
        private uploadProtocol: ProtocolUploadService,
        private loading: LoadingService,
        private states: StateService
    ) {
        this.initStorageEvent();
    }

    get vehicles() {
        return this._vehicles;
    }

    public get lastLoaded(): Date {
        return this._lastLoaded;
    }

    public set lastLoaded(date: Date) {
        this._lastLoaded = date;
    }

    get(id: number) {
        return this._vehicles.find(v => v.id === id);
    }

    set(vehicle: Vehicle) {
        if (!vehicle.id || !this._vehicles.find(v => v.id === vehicle.id)) {
            this.add(vehicle);
            return;
        }
        for (const i in this._vehicles) {
            if (this._vehicles[i].id !== vehicle.id) {
                continue;
            }
            this._vehicles[i] = vehicle;
        }
        this.vehiclesChanged.emit([vehicle]);
        this.updateStorage();
    }

    add(vehicle: Vehicle) {
        const elementExists = this.get(vehicle.id);
        if (!elementExists) {
            this._vehicles.push(vehicle);
            this.vehiclesAdded.emit([vehicle]);
            this.updateStorage();
        }
    }

    remove(idVehicle: number) {
        const vehicle = this.get(idVehicle);
        if (vehicle) {
            const index = this._vehicles.indexOf(vehicle);
            this._vehicles.splice(index, 1);
            this.vehiclesRemoved.emit([vehicle]);
            this.updateStorage();
        }
    }

    async loadList(): Promise<Vehicle[]> {
        // Falls bereits ein Fahrzeug mit vehicles.loadOneFromServer geladen wurde,
        // muss trotzdem die ganze Liste geladen werden.
        // Daher gehen wir davon aus, dass die Liste bereits geladen wurde,
        // falls mindestens zwei Einträge vorhanden sind.
        if (Array.isArray(this._vehicles) && this._vehicles.length > 1) {
            return this._vehicles;
        }
        await this.loadFromStorage();
        if (Array.isArray(this._vehicles) && this._vehicles.length > 1) {
            return this._vehicles;
        }
        return await this.loadFromServer();
    }

    async loadFromStorage(): Promise<Vehicle[]> {
        if (!environment.mobile) {
            return [];
        }
        const vehicles = await this.storage.get('nf.vehicle');
        if (!Array.isArray(vehicles)) {
            return [];
        }
        this._vehicles = vehicles;
        return this._vehicles;
    }

    async loadFromServer(): Promise<Vehicle[]> {
        let page = 1;
        this._vehicles = [];
        while (page <= this.maxPages || this.maxPages === 0) {
            await this.loadPageFromServer(page);
            page++;
        }
        await this.updateStorage();
        this._lastLoaded = new Date();
        return this._vehicles;
    }

    private async loadPageFromServer(page: number) {
        this.loading.setMessage('Fahrzeuge werden geladen ... Seite ' + page + (this.maxPages > 0 ? ' von ' + this.maxPages : ''));
        const url = '/v1/fleet/vehicle?page=' + page + '&limit=' + this._limit;
        const response = await sendGet(this.http, url).toPromise() as VehicleResponse;
        this._vehicles = this._vehicles.concat(response.data);
        this.maxPages = response.countResultPages;
        this.vehiclesAdded.emit(response.data as Vehicle[]);
        return response.data as Vehicle[];
    }

    async loadOneFromServer(id: number) {
        const url = '/v1/fleet/vehicle/' + id;
        const response = await sendGet(this.http, url).toPromise() as SingleVehicleResponse;
        this.set(response.data as Vehicle);
        return response.data as Vehicle;
    }

    private async updateStorage() {
        if (!environment.mobile) {
            // todo: wieder rein nehmen, überfüllt den Storage aktuell
            // localStorage.setItem('update-vehicles', JSON.stringify(this._vehicles));
            return;
        }
        return await this.storage.set('nf.vehicle', this._vehicles);
    }

    get unsyncedVehicles() {
        return this.vehicles.filter(v => v.needSync);
    }

    /**
     * @return number - Anzahl der fehlgeschlagenen Uploads
     */
    async uploadToServer() {
        const unsyncedVehicles = this.unsyncedVehicles;
        if (unsyncedVehicles.length === 0) {
            return 0;
        }
        let errors = 0;
        for (const vehicle of unsyncedVehicles) {
            if (!vehicle.aspiredFleetStatus || vehicle.aspiredFleetStatus === vehicle.fleetStatus) {
                continue;
            }
            const message = 'Lade ausgefüllte Protokolle hoch ... \nFahrzeug '
                + (unsyncedVehicles.indexOf(vehicle) + 1)
                + ' von ' + unsyncedVehicles.length;
            this.loading.setMessage(message);
            errors = await this.uploadOneToServer(vehicle) ? errors : errors + 1;
        }
        return errors;
    }

    private async uploadOneToServer(vehicle: Vehicle) {
        const form = await this.protocols.getOneFromStorage(vehicle.id);
        if (form) {
            const result = await this.uploadProtocol.store(form).catch(e => {
                reportError(e);
                return e;
            });
            if (result instanceof Error || result instanceof HttpErrorResponse) {
                return false;
            }
        } else {
            const updatedVehicle = await this.states.uploadStateChange(vehicle).catch(e => {
                reportError(e);
                return e;
            });
            if (updatedVehicle instanceof Error || updatedVehicle instanceof HttpErrorResponse) {
                return false;
            }
            vehicle.fleetStatus = updatedVehicle.fleetStatus;
        }
        vehicle.needSync = false;
        vehicle.aspiredFleetStatus = undefined;
        this.set(vehicle);
        return true;
    }

    set limit(newLimit: number) {
        this._limit = newLimit;
    }

    initStorageEvent() {
        window.addEventListener('storage', (event) => {
            if (event.key === 'vehicle-update') {
                console.log('VehicleUpdate: ', event.newValue);
                this._vehicles = JSON.parse(event.newValue);
                this.vehiclesChanged.emit(this._vehicles);
                this.updateStorage();
            }
        }, false);
    }
}

function sleep(time: number) {
    return new Promise(resolve => setTimeout(resolve, time));
}
