import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NavigationComponent} from './component/navigation/navigation.component';
import {IonicModule} from '@ionic/angular';
import {RouterModule} from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';


@NgModule({
    declarations: [
        NavigationComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        IonicModule,
        MatMenuModule,
        MatIconModule
    ],
    exports: [
        NavigationComponent
    ]
})
export class SharedModule {
}
