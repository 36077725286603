import { Injectable } from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {EMPTY, Observable, throwError} from 'rxjs';
import {ToastService} from '../service/toast/toast.service';
import {environment} from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OfflineInterceptor implements HttpInterceptor {

  constructor(
      private toast: ToastService,
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      if ((req.url.startsWith('http://') || req.url.startsWith('https://')) && !navigator.onLine && environment.mobile) {
          return throwError(new HttpErrorResponse({ error: 'Sie sind offline.' }));
      }
      return next.handle(req);
  }
}
