import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {IonicModule} from '@ionic/angular';

import {VehiclesPageRoutingModule} from './vehicles-routing.module';

import {VehiclesPage} from './component/vehicles/vehicles.page';
import {SharedModule} from '../../shared/shared.module';
import {VehicleComponent} from './component/vehicle/vehicle.component';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import {NbFormModule} from 'nb-form';
import {VehiclesTableComponent} from './component/table/table.component';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatTableModule} from '@angular/material/table';
import {MatSortModule} from '@angular/material/sort';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatTooltipModule} from '@angular/material/tooltip';
import {StateButtonComponent} from './component/vehicle/state-button/state-button.component';
import {VehicleDetailsComponent} from './component/vehicle/vehicle-details/vehicle-details.component';
import {IonicStorageModule} from '@ionic/storage';
import {VehicleIssueModalComponent} from '../issues/component/vehicle-issue-modal/vehicle-issue-modal.component';
import {VehicleIssuesComponent} from '../issues/component/vehicle-issues/vehicle-issues.component';
import {VehicleModalComponent} from './component/vehicle/vehicle-modal/vehicle-modal';
import {VehicleCommentsComponent} from './component/vehicle/vehicle-comments/vehicle-comments.component';
import {ProtocolsComponent} from './component/vehicle/protocols/protocols.component';
import {PossibleStatesComponent} from './component/vehicle/possible-states/possible-states.component';
import {VehicleSimpleComponent} from './component/vehicle-simple/vehicle-simple.component';
import { AllocationModalComponent } from './component/vehicle/allocation-modal/allocation-modal';
import {Insomnia} from '@ionic-native/insomnia/ngx';
import {VehicleDamagesComponent} from './component/vehicle/vehicle-damages/vehicle-damages.component';
import {AccModule} from '../../acc/acc.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        VehiclesPageRoutingModule,
        SharedModule,
        MatButtonModule,
        MatFormFieldModule,
        NbFormModule,
        MatPaginatorModule,
        MatTableModule,
        MatSortModule,
        MatInputModule,
        MatSelectModule,
        ReactiveFormsModule,
        MatCheckboxModule,
        MatIconModule,
        MatMenuModule,
        MatSnackBarModule,
        MatTooltipModule,
        IonicStorageModule,
        MatGridListModule,
        AccModule
    ],
    declarations: [
        VehiclesPage,
        VehicleComponent,
        VehiclesTableComponent,
        StateButtonComponent,
        VehicleDetailsComponent,
        VehicleModalComponent,
        VehicleCommentsComponent,
        VehicleIssueModalComponent,
        VehicleIssuesComponent,
        ProtocolsComponent,
        PossibleStatesComponent,
        VehicleSimpleComponent,
        AllocationModalComponent,
        VehicleDamagesComponent
    ],
    entryComponents: [
        VehicleModalComponent,
        VehicleIssueModalComponent,
        AllocationModalComponent
    ],
    providers: [
        Insomnia
    ]
})
export class VehiclesPageModule {
}
