import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {VehicleDamageModel} from '../../../model/vehicle-damage.model';
import {VehicleDamageService} from '../../../service/damage/vehicle-damage.service';
import {from, Observable, Subscription} from 'rxjs';
import {LoadingService} from '../../../../../shared/service/loading/loading.service';
import {switchMap, tap} from 'rxjs/operators';
import {ProtocolService} from '../../../../protocol/service/protocol.service';
import {ProtocolUploadService} from '../../../../protocol/service/protocol-upload.service';

@Component({
    selector: 'app-vehicle-damages',
    templateUrl: './vehicle-damages.component.html',
    styleUrls: ['./vehicle-damages.component.scss'],
})
export class VehicleDamagesComponent implements OnInit, OnDestroy {

    @Input() vehicle: number;
    @Input() $damages: Observable<VehicleDamageModel[]>;

    private changeSub: Subscription;

    constructor(
        private damageService: VehicleDamageService,
        private loadingService: LoadingService,
        private protocolUploadService: ProtocolUploadService
    ) {
    }

    async ngOnInit() {
        if (this.vehicle && !this.$damages) {
            this.$damages = this.damageService.loadList(this.vehicle);
        }
        this.changeSub = this.protocolUploadService.dataChanged.subscribe(() => this.clickRefresh());
    }

    ngOnDestroy() {
        if (this.changeSub) {
            this.changeSub.unsubscribe();
        }
    }

    clickRefresh() {
        this.$damages = this.damageService.loadList(this.vehicle);
    }

}
