import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AuthService} from '../../page/account/service/auth/auth.service';
import {environment} from '../../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class AccService {

    constructor(private http: HttpClient, private authService: AuthService) {
    }

    isRouteAllowed(route: string, method?: string) {
        if (environment.ignoreAcc) {
            return true;
        }
        if (!method) {
            method = 'GET';
        }
        method = method.toUpperCase();
        if (this.authService.acl.length === 0) {
            return false;
        }
        return !!this.authService.acl.find(a => a.route === route && a.method.toUpperCase() === method);
    }

    async isUrlAllowed(url: string) {
        return !!(await this.http.options(url).toPromise().catch(e => false));
    }

}
