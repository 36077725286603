import {Injectable} from '@angular/core';
import {ToastController} from '@ionic/angular';
import {HttpErrorResponse} from '@angular/common/http';
import {reportError} from '../../error/sentry.error-handler';

interface ToastOptions {
    header?: string;
    message?: string;
    duration?: number;
    position?: 'top' | 'bottom' | 'middle';
}


@Injectable({
    providedIn: 'root'
})
export class ToastService {

    constructor(private toast: ToastController) {
    }

    async show(options: ToastOptions) {
        const toast = await this.toast.create(options);
        return await toast.present();
    }

    error(message: string) {
        return this.show({
            duration: 5000,
            message
        });
    }

    success(message: string) {
        return this.show({
            duration: 1000,
            message
        });
    }

    httpError(e: any, fallbackMessage?: string) {
        reportError(e);
        if (e instanceof HttpErrorResponse) {
            let message = e.statusText;
            if (typeof e.error === 'string') {
                message = e.error;
            } else if (typeof e.error === 'object' && typeof e.error.message === 'string') {
                message = e.error.message;
            }
            return this.error(message);
        }
        return this.error(fallbackMessage ? fallbackMessage : 'Unbekannter Fehler');
    }

}
