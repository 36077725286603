import {Component, OnInit} from '@angular/core';
import {Vehicle} from '../../../vehicles/model/vehicle.model';
import {IssuesService} from '../../service/issues/issues.service';
import {ModalController} from '@ionic/angular';
import {VehicleIssueService} from '../../service/vehicle-issue/vehicle-issue.service';
import {FormType} from '../../../../shared/model/form-type';
import {AlertsService, Form, FormElement, FormPage, ModifyDataService, NbFormDataService, NbFormValidatorService} from 'nb-form';
import {LoadingService} from '../../../../shared/service/loading/loading.service';
import {FleetVehicleIssue} from '../../../timeline/interface/FleetVehicleIssue';
import {FormControl} from '@angular/forms';
import {ToastService} from '../../../../shared/service/toast/toast.service';
import {VehicleIssueForm} from '../../model/vehicle-issue-form';
import {moment} from 'vis-timeline';

@Component({
    selector: 'app-vehicle-issue-modal',
    templateUrl: './vehicle-issue-modal.component.html',
    styleUrls: ['./vehicle-issue-modal.component.scss'],
})
export class VehicleIssueModalComponent implements OnInit {

    title: string;
    type: FormType;

    vehicle: Vehicle;
    vehicles: Vehicle[];

    vehicleIssue: FleetVehicleIssue;

    form: Form;

    color = new FormControl('');

    lastSelectedFleetIssueId: number;

    private dateValidator = [
        {
            func: 'greater',
            parameters: [
                'endTime',
                'startTime'
            ],
        }
    ];

    constructor(
        public issues: IssuesService,
        public vehicleIssues: VehicleIssueService,
        private modalController: ModalController,
        private alert: AlertsService,
        private loading: LoadingService,
        private validator: NbFormValidatorService,
        private toast: ToastService,
        private modifyDataService: ModifyDataService,
        private nbFormDataService: NbFormDataService
    ) {
    }

    async ngOnInit() {
        await this.loading.show();
        await this.issues.load();

        this.form = new Form();
        this.form.schema = this.getSchema();

        if (this.type === FormType.New) {
            this.form.data = {};
        } else if (this.type === FormType.Edit) {
            this.form.data = {
                fleetIssueId: this.vehicleIssue.fleetIssue.id,
                name: this.vehicleIssue.name,
                description: this.vehicleIssue.description,
                startTime: this.vehicleIssue.startTime,
                _startDate: this.vehicleIssue.startTime,
                _startTime: this.getTime(this.vehicleIssue.startTime),
                endTime: this.vehicleIssue.endTime,
                _endDate: this.vehicleIssue.endTime,
                _endTime: this.getTime(this.vehicleIssue.endTime),
            };
        }
        this.modifyDataService.helper.formCurrentFleetVehicleIssue = this.formCurrentFleetVehicleIssue();
        this.modifyDataService.helper.onSelectionChange = this.onSelectionChange();

        await this.loading.hide();
    }

    private formCurrentFleetVehicleIssue() {
        return () => {
            const startDate = this.nbFormDataService._read('_startDate');
            const endDate = this.nbFormDataService._read('_endDate');
            const startTimeString = this.nbFormDataService._read('_startTime');
            const endTimeString = this.nbFormDataService._read('_endTime');

            if (startDate && endDate && startTimeString && endTimeString) {
                const start = moment(startDate);
                const end = moment(endDate);

                const sHours = parseInt(startTimeString.split(':')[0], 10);
                const sMinutes = parseInt(startTimeString.split(':')[1], 10);
                const eHours = parseInt(endTimeString.split(':')[0], 10);
                const eMinutes = parseInt(endTimeString.split(':')[1], 10);

                start.set({hour: sHours, minute: sMinutes, second: 0, millisecond: 0});
                end.set({hour: eHours, minute: eMinutes, second: 0, millisecond: 0});

                this.nbFormDataService._write('startTime', start.format('YYYY-MM-DDTHH:mm:ssZ'));
                this.nbFormDataService._write('endTime', end.format('YYYY-MM-DDTHH:mm:ssZ'));
            }
        };
    }

    private onSelectionChange() {
        return async () => {
            const fleetIssueId = this.nbFormDataService._read('fleetIssueId');
            if (fleetIssueId === this.lastSelectedFleetIssueId) {
                return;
            }
            if (this.lastSelectedFleetIssueId) {
                const confirm = await this.alert.confirm({
                    text: 'Wollen Sie den Auftragstyp ändern? <br> Diese Aktion überschreibt die Felder "Name", "Beschreibung" ' +
                        'und "Farbe" mit den Daten aus dem ausgewählten Auftragstyp ',
                    header: 'Auftragstyp ändern',
                }).catch(() => {
                    this.nbFormDataService._write('fleetIssueId', this.lastSelectedFleetIssueId);
                    return false;
                });
                if (!confirm) {
                    return;
                }
            }
            const template = this.issues.get(fleetIssueId);
            if (!template) {
                return;
            }
            this.nbFormDataService._write('name', template.name);
            this.nbFormDataService._write('description', template.description);
            this.color.setValue(template.color);
            this.lastSelectedFleetIssueId = fleetIssueId ? fleetIssueId : 0;
        };
    }

    getTime(date) {
        return moment(date).format('HH:mm');
    }

    getColorValue(): string {
        return this.vehicleIssue ? this.vehicleIssue.color : '#ffffff';
    }

    async save() {
        if (!this.validator.validate()) {
            await this.toast.error('Bitte überprüfen Sie Ihre Formulareingaben');
            return;
        }
        await this.loading.show();
        const formData = this.form.data as VehicleIssueForm;
        formData.color = this.color.value ? this.color.value : this.getColorValue();
        delete formData._startTime;
        delete formData._startDate;
        delete formData._endTime;
        delete formData._endDate;

        let result = false;
        if (this.type === FormType.New) {
            if (this.vehicle) {
                result = await this.vehicleIssues.
                store(this.vehicle.id, formData);
            } else if (this.vehicles) {
                result = await this.vehicleIssues.storeBulk(this.vehicles, formData);
            }
        } else if (this.type === FormType.Edit) {
            result = await this.vehicleIssues.update(this.vehicleIssue.id, this.vehicle.id, formData);
        }
        if (result) {
            await this.modalController.dismiss(true);
        }
        await this.loading.hide();
    }

    async dismiss() {
        await this.modalController.dismiss(false);
    }

    async delete() {
        const confirmed = await this.alert.confirm({
            text: 'Möchten sie diesen Auftrag wirklich löschen?',
            header: 'Auftrag löschen?'
        }).catch(() => {
            return;
        });
        if (confirmed) {
            await this.vehicleIssues.delete(this.vehicleIssue.id, this.vehicle.id);
            await this.modalController.dismiss(true);
        }
    }

    private getSchema(): FormPage[] {
        let elements = [
            {
                id: '0-2',
                type: 'textarea',
                label: 'Name',
                model: 'name',
                required: true
            },
            {
                id: '0-3',
                type: 'textarea',
                label: 'Beschreibung',
                model: 'description'
            },
            {
                id: '0-4',
                type: 'date',
                label: 'Startdatum',
                model: '_startDate',
                required: true,
                onChange: {func: 'formCurrentFleetVehicleIssue'},
                valid: this.dateValidator,
                messages: {
                    nbFormInvalid: 'Der Startzeitpunkt muss vor dem Endzeitpunkt liegen.',
                }
            },
            {
                id: '0-5',
                type: 'time',
                label: 'Startzeit',
                model: '_startTime',
                required: true,
                onChange: {func: 'formCurrentFleetVehicleIssue'},
                valid: this.dateValidator,
                messages: {
                    nbFormInvalid: 'Der Startzeitpunkt muss vor dem Endzeitpunkt liegen.',
                }
            },
            {
                id: '0-6',
                type: 'date',
                label: 'Enddatum',
                model: '_endDate',
                required: true,
                onChange: {func: 'formCurrentFleetVehicleIssue'},
                valid: this.dateValidator,
                messages: {
                    nbFormInvalid: 'Der Startzeitpunkt muss vor dem Endzeitpunkt liegen.',
                }
            },
            {
                id: '0-7',
                type: 'time',
                label: 'Endzeit',
                model: '_endTime',
                required: true,
                onChange: {func: 'formCurrentFleetVehicleIssue'},
                valid: this.dateValidator,
                messages: {
                    nbFormInvalid: 'Der Startzeitpunkt muss vor dem Endzeitpunkt liegen.',
                }
            }
        ] as FormElement[];
        if (this.type === FormType.New) {
            elements = ([
                {
                    id: '0-1',
                    type: 'nb-dropdown',
                    label: 'Auftragstyp',
                    model: 'fleetIssueId',
                    options: this.getOptions(),
                    required: true,
                    onChange: {
                        func: 'onSelectionChange'
                    }
                }
            ] as FormElement[]).concat(elements);
        }
        return [
            {
                ber_reiter_id: 'fleetIssue',
                elements
            } as FormPage
        ];
    }

    private getOptions() {
        const options = [];
        this.issues.issues.forEach(issue => {
            const data = {
                key: issue.id,
                value: issue.name,
            };
            options.push(data);
        });
        return options;
    }
}
