import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Comment } from '../../../model/comment.model';
import moment from 'moment';

@Component({
    selector: 'app-vehicle-comments',
    templateUrl: './vehicle-comments.component.html',
    styleUrls: ['./vehicle-comments.component.scss'],
})
export class VehicleCommentsComponent implements OnChanges {

    @Input() comments: Comment[];

    displayComments: Comment[] = [];

    constructor() {}


    ngOnChanges(changes: SimpleChanges) {
        if (changes.comments) {
            if (this.comments.length > 1) {
                this.displayComments = this.comments.sort((a: Comment, b: Comment) => {
                    return (a.createdAt < b.createdAt) ? 1 : 0;
                });
            } else {
                this.displayComments = this.comments;
            }
        }
    }

    format(date: Date): string {
        moment.locale('de');
        return moment(date).format('L LTS');
    }

}
