import {EventEmitter, Injectable, Output} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Issue} from '../../model/issue.model';
import {IssueResponse} from '../../response/issue.response';
import {AutoUpdate} from 'src/app/module/shared/model/autoupdate';
import {reportError} from '../../../../shared/error/sentry.error-handler';
import {ToastService} from '../../../../shared/service/toast/toast.service';
import {sendDelete, sendGet, sendPatch, sendPost} from '../../../../shared/function/api-url.function';

@Injectable({
    providedIn: 'root'
})
export class IssuesService implements AutoUpdate {

    private maxPages = 0;

    private limit = 25;

    private _issues: Issue[] = [];

    private _lastLoaded: Date = null;

    @Output() onChange = new EventEmitter<Issue[]>();

    constructor(
        private http: HttpClient,
        private toast: ToastService,
    ) {
        this.initStorageEvent();
    }

    public get lastLoaded(): Date {
        return this._lastLoaded;
    }

    public set lastLoaded(date: Date) {
        this._lastLoaded = date;
    }

    get issues() {
        return this._issues;
    }

    get(id: number) {
        return this._issues.find(i => i.id === id);
    }

    async load(): Promise<Issue[]> {
        let page = 1;
        this._issues = [];
        while (page <= this.maxPages || this.maxPages === 0) {
            await this.loadNextPage(page);
            page++;
        }
        this._lastLoaded = new Date();
        return this._issues;
    }

    private async loadNextPage(page: number) {
        const url = '/v1/fleet/issue?page=' + page + '&limit=' + this.limit;
        const response = await sendGet(this.http, url).toPromise() as IssueResponse;
        this._issues = this._issues.concat(response.data as Issue[]);
        this.maxPages = response.countResultPages;
        return this._issues;
    }

    async store(issueData: any) {
        const response = await sendPost(this.http, '/v1/fleet/issue', {issue: issueData})
            .toPromise().catch((e: HttpErrorResponse) => {
                reportError(e);
                return e;
            }) as any;

        if (!(response instanceof HttpErrorResponse)) {
            this._issues.push(response.data);
            await this.toast.success('Erfolgreich gespeichert');
            return true;
        }
        await this.toast.error(this.parseErrors(response));
        return false;
    }

    async update(id: number, issueData: any) {
        const url = '/v1/fleet/issue/' + id;
        const response = await sendPatch(this.http, url, {issue: issueData})
            .toPromise().catch((e) => {
                reportError(e);
                return e;
            }) as any;

        if (!(response instanceof HttpErrorResponse)) {
            const element = this._issues.find((issue: Issue) => {
                return (issue.id === id);
            });
            const newElement = response.data as Issue;
            const indexOf = this._issues.indexOf(element);
            this._issues[indexOf] = newElement;
            await this.toast.success('Erfolgreich gespeichert');
            return true;
        }

        await this.toast.error(this.parseErrors(response));
        return false;
    }

    async delete(id) {
        const url = '/v1/fleet/issue/' + id;
        const response = await sendDelete(this.http, url).toPromise()
            .catch((e: HttpErrorResponse) => {
                reportError(e);
                return e;
            });

        if (!(response instanceof HttpErrorResponse)) {
            this.remove(id);
        }
    }

    parseErrors(e: HttpErrorResponse): string {
        const errors = e.error.errors;
        let message = '';

        errors.map((error) => {
            const tempString = 'Feld' + error.field + ': ' + error.message;
            message += tempString + '<br>';
        });
        return message;
    }

    add(issue: Issue) {
        const elementExists = this.get(issue.id);
        if (!elementExists) {
            this._issues.push(issue);
            this._issues = this._issues.sort((a, b) => {
                return (a.name > b.name) ? 1 : 0;
            });
            this.setUpdatedIssues();
            this.onChange.emit(this._issues);
        }
    }

    set(issue: Issue) {
        const foundIssue = this.get(issue.id);
        if (foundIssue) {
            const index = this._issues.indexOf(foundIssue);
            this._issues[index] = issue;
            this.setUpdatedIssues();
            this.onChange.emit(this._issues);
        }
    }

    remove(idIssue: number) {
        const foundIssue = this.get(idIssue);
        if (foundIssue) {
            const index = this._issues.indexOf(foundIssue);
            this._issues.splice(index, 1);
            this.setUpdatedIssues();
            this.onChange.emit(this._issues);
        }
    }

    initStorageEvent() {
        window.addEventListener('storage', (event) => this.onStorageUpdate(event), false);
    }

    onStorageUpdate(event) {
        if (event.key === 'issue-update') {
            this._issues = JSON.parse(event.newValue);
            console.log('NewIssues: ', this._issues);
            this.onChange.emit(this._issues);
        }
    }

    setUpdatedIssues() {
        localStorage.setItem('issue-update', JSON.stringify(this._issues));
    }

}
