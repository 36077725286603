import {HttpErrorResponse} from '@angular/common/http';

export function getErrorMessage(e: HttpErrorResponse | Error | string) {
    if (e instanceof HttpErrorResponse) {
        if (e.headers.get('content-type') && e.headers.get('content-type').startsWith('text/html')) {
            return e.statusText.trim();
        }
        if (typeof e.error === 'string') {
            return e.error.trim();
        }
        if (typeof e.error === 'object' && typeof e.error.message === 'string') {
            return e.error.message.trim();
        }
        return e.statusText.trim();
    }
    if (!e) {
        return 'Unbekannter Fehler';
    }
    if (typeof e === 'string') {
        return e.trim();
    }
    if (typeof e.message !== 'string') {
        return 'Unbekannter Fehler';
    }
    return e.message.trim();
}
