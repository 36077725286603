import {EventEmitter, Injectable, Output} from '@angular/core';
import {Location} from '../model/location.model';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {LocationResponse} from '../response/location.response';
import {AutoUpdate} from 'src/app/module/shared/model/autoupdate';
import {reportError} from '../../../shared/error/sentry.error-handler';
import {ToastService} from '../../../shared/service/toast/toast.service';
import {sendDelete, sendGet, sendPatch, sendPost} from '../../../shared/function/api-url.function';

@Injectable({
    providedIn: 'root'
})
export class LocationsService implements AutoUpdate {

    private maxPages = 0;

    private limit = 25;

    private _locations: Location[] = [];

    private _lastLoaded: Date = null;

    @Output()
    onChange = new EventEmitter<Location[]>();

    constructor(
        private http: HttpClient,
        private toast: ToastService
    ) {
        this.initStorageEvent();
    }

    public get lastLoaded(): Date {
        return this._lastLoaded;
    }

    public set lastLoaded(date: Date) {
        this._lastLoaded = date;
    }

    get locations() {
        return this._locations;
    }

    get(id: number) {
        return this._locations.find(l => l.id === id);
    }

    async load(): Promise<Location[]> {
        let page = 1;
        this._locations = [];
        while (page <= this.maxPages || this.maxPages === 0) {
            await this.loadNextPage(page);
            page++;
        }
        this._lastLoaded = new Date();
        return this._locations;
    }

    private async loadNextPage(page: number) {
        const url = '/v1/fleet/location?page=' + page + '&limit=' + this.limit;
        const response = await sendGet(this.http, url).toPromise() as LocationResponse;
        this._locations = this._locations.concat(response.data as Location[]);
        this.maxPages = response.countResultPages;
        return this._locations;
    }

    async store(location: any): Promise<boolean> {
        const response = await sendPost(this.http, '/v1/fleet/location', {
            location
        })
            .toPromise().catch((e: HttpErrorResponse) => {
                reportError(e);
                return e;
            }) as any;
        if (!(response instanceof HttpErrorResponse)) {
            this._locations.push(response.data);
            await this.toast.success('Erfolgreich gespeichert');
            return true;
        }
        await this.toast.error(this.parseErrors(response));
        return false;
    }

    async update(id: number, locationData: any) {
        const url = '/v1/fleet/location/' + id;
        const response = await sendPatch(this.http, url, {location: locationData})
            .toPromise().catch((e: HttpErrorResponse) => {
                reportError(e);
                return e;
            }) as any;

        if (!(response instanceof HttpErrorResponse)) {
            const element = this._locations.find((location: Location) => {
                return (location.id === id);
            });
            const newElement = response.data as Location;
            const indexOf = this._locations.indexOf(element);
            this._locations[indexOf] = newElement;
            await this.toast.success('Erfolgreich gespeichert');
            return true;
        }
        await this.toast.error(this.parseErrors(response));
        return false;
    }

    async delete(id) {
        const response = await sendDelete(this.http, '/v1/fleet/location/' + id)
            .toPromise().catch((e: HttpErrorResponse) => {
                return e;
            });
        if (!(response instanceof HttpErrorResponse)) {
            this._locations = this._locations.filter(l => l.id !== id);
        }
    }

    sort() {
        this._locations.sort((a, b) => {
            return (a.name > b.name) ? 1 : 0;
        });
    }

    parseErrors(e: HttpErrorResponse): string {
        const errors = e.error.errors;
        let message = '';

        errors.map((error) => {
            const tempString = 'Feld' + error.field + ': ' + error.message;
            message += tempString + '<br>';
        });
        return message;
    }

    add(location: Location) {
        const elementExists = this.get(location.id);
        if (!elementExists) {
            this._locations.push(location);
            this.sort();
            this.setUpdatedLocations();
            this.onChange.emit(this._locations);
        }
    }

    set(updateLocation: Location) {
        const foundElement = this.get(updateLocation.id);

        if (foundElement) {
            const index = this._locations.indexOf(foundElement);
            this._locations[index] = updateLocation;
            this.sort();
            this.setUpdatedLocations();
            this.onChange.emit(this._locations);
        }
    }

    remove(idLocation: number) {
        const foundElement = this.get(idLocation);
        if (foundElement) {
            const index = this._locations.indexOf(foundElement);
            this._locations.splice(index, 1);
            this._locations.sort();
            this.setUpdatedLocations();
            this.onChange.emit(this._locations);
        }
    }

    initStorageEvent() {
        window.addEventListener('storage', (event) => this.onStorageUpdate(event), false);
    }

    onStorageUpdate(event) {
        if (event.key === 'location-update') {
            this._locations = JSON.parse(event.newValue);
            console.log('newLocations: ', this._locations);
            this.onChange.emit(this._locations);
        }
    }

    setUpdatedLocations() {
        localStorage.setItem('location-update', JSON.stringify(this._locations));
    }

}
