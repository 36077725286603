import {EventEmitter, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AuthService, Session} from '../auth/auth.service';
import {map, tap} from 'rxjs/operators';
import {sendPost} from '../../../../shared/function/api-url.function';

@Injectable({
    providedIn: 'root'
})
export class LoginService {

    login = new EventEmitter<Session>();

    constructor(private http: HttpClient, private auth: AuthService) {
    }

    async send(username: string, password: string) {
        const formData = new FormData();
        formData.append('username', username);
        formData.append('password', password);
        this.auth.session = await sendPost(this.http, '/login', formData).pipe(
            map(response => response as Session),
            tap(session => this.login.emit(session))
        ).toPromise() as Session;
    }

}
