import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { LoadingService } from 'src/app/module/shared/service/loading/loading.service';
import { Form, FormPage, NbFormValidatorService } from 'nb-form';
import { VehicleService } from '../../../service/vehicle/vehicle.service';
import { Vehicle } from '../../../model/vehicle.model';
import { ToastService } from 'src/app/module/shared/service/toast/toast.service';
import { environment } from '../../../../../../../environments/environment';
import { HttpErrorResponse } from '@angular/common/http';
import { LocationsService } from 'src/app/module/page/locations/service/locations.service';
import { GroupsService } from 'src/app/module/page/groups/service/groups.service';
import { AllocationTypes } from '../../../model/allocation-types';
import {reportError} from '../../../../../shared/error/sentry.error-handler';

@Component({
    selector: 'app-allocation-modal',
    templateUrl: './allocation-modal.html',
    styleUrls: ['./allocation-modal.scss'],
})
export class AllocationModalComponent implements OnInit {

    title: string;

    options: any[];

    form: Form;

    type: string;

    vehicles: Vehicle[] = null;

    private _labels = {
        fleetLocation: 'Standort auswählen',
        fleetGroup: 'Gruppe auswählen'
    };

    private _keys = {
        fleetLocation: 'fleetLocationId',
        fleetGroup: 'fleetGroupId'
    };

    public get label(): string {
        const label = this._labels[this.type];
        if (label) {
            return label;
        }
        return '';
    }

    public get key(): string {
        const key = this._keys[this.type];
        if (key) {
            return key;
        }
        return '';
    }

    constructor(
        private vehicleService: VehicleService,
        private modalCtrl: ModalController,
        private locationService: LocationsService,
        private groupService: GroupsService,
        private loading: LoadingService,
        private formValidator: NbFormValidatorService,
        private toast: ToastService
    ) { }

    async ngOnInit() {
        await this.loading.show();
        this.options = await this.getOptions();
        this.form = new Form();
        this.form.schema = this.getSchema();
        this.form.data = {};
        await this.loading.hide();
    }

    async getOptions(): Promise<any[]> {
        const options = [];
        options.push({
            key: 0,
            value: 'Keine Auswahl'
        });

        if (this.type === AllocationTypes.FleetGroup) {
            const entities = await this.groupService.load();
            entities.map((entity) => {
                options.push({
                    key: entity.id,
                    value: entity.name
                });
            });
        } else if (this.type === AllocationTypes.FleetLocation) {
            const entities = await this.locationService.load();
            entities.map((entity) => {
                options.push({
                    key: entity.id,
                    value: entity.name
                });
            });
        }

        return options;
    }

    async save() {
        if (this.formValidator.validate() === false) {
            await this.toast.error('Bitte überprüfen Sie Ihre Eingaben');
            return;
        }

        await this.loading.show();
        this.vehicles.map(async (vehicle: Vehicle) => {
            const vehicleData = this.vehicleService.parseVehicleData(vehicle);
            vehicleData[this.key] = this.form.data[this.key];

            await this.vehicleService.update(vehicleData, vehicle.id).catch((e: HttpErrorResponse) => {
                reportError(e);
                this.toast.error('Fehler bei der Anfrage');
                this.loading.hide();
                return;
            });
        });

        this.toast.success('Erfolgreich gespeichert');
        await this.loading.hide();
        this.modalCtrl.dismiss(true);
    }

    dismiss() {
        this.modalCtrl.dismiss(false);
    }

    private getSchema(): FormPage[] {
        return [
            {
                ber_reiter_id: 'vehicle-allocation',
                elements: [
                    {
                        id: '0-1',
                        type: 'nb-dropdown',
                        label: this.label,
                        model: this.key,
                        options: this.options
                    }
                ]
            } as FormPage
        ];
    }

    get debug() {
        return !environment.production;
    }
}
