import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router} from '@angular/router';
import {AuthService} from '../../service/auth/auth.service';
import {environment} from '../../../../../../environments/environment';
import {AccService} from '../../../../acc/service/acc.service';
import {AlertsService} from 'nb-form';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {
    constructor(
        private auth: AuthService,
        private router: Router,
        private accService: AccService,
        private alertsService: AlertsService
    ) {
    }

    canActivate(route: ActivatedRouteSnapshot) {
        if (!this.auth.isAuthenticated()) {
            const url = environment.mobile ? '/tabs/account/login' : '/account/login';
            this.router.navigate([url]);
            return false;
        }
        if (route.data && route.data.route && !this.accService.isRouteAllowed(route.data.route, route.data.method)) {
            console.warn('ACC NOT ALLOWED', route.data);
            this.alertsService.toast({duration: 2000, message: 'Diese Aktion ist für Sie nicht erlaubt.'})
            return false;
        }

        return true;
    }
}
