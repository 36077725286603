import {Injectable, OnDestroy} from '@angular/core';
import {environment} from 'src/environments/environment';
import {interval, Subscription} from 'rxjs';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {VehiclesService} from 'src/app/module/page/vehicles/service/vehicles/vehicles.service';
import {IssuesService} from 'src/app/module/page/issues/service/issues/issues.service';
import {LocationsService} from 'src/app/module/page/locations/service/locations.service';
import moment from 'moment';
import {UpdateResponse} from '../../model/update-response';
import {GroupsService} from 'src/app/module/page/groups/service/groups.service';
import {AutoUpdate} from '../../model/autoupdate';
import {Event, NavigationEnd, NavigationStart, Router} from '@angular/router';
import {reportError} from '../../error/sentry.error-handler';
import {sendGet} from '../../function/api-url.function';

let _autoUpdater: AutoUpdateService;

@Injectable({
    providedIn: 'root'
})
export class AutoUpdateService implements OnDestroy {

    private _runUpdates: boolean;

    private _updateInterval: number;

    private _updateType: string;

    private _intervalSubscription: Subscription;

    private _routerSubscription: Subscription;

    private _types = {
        '/vehicles': 'fleetVehicle',
        '/timeline': 'fleetVehicle',
        '/groups': 'fleetGroup',
        '/groups/list': 'fleetGroup',
        '/issues': 'fleetIssue',
        '/locations': 'fleetLocation'
    };

    constructor(
        public http: HttpClient,
        private vehicles: VehiclesService,
        private issue: IssuesService,
        private locations: LocationsService,
        private groups: GroupsService,
        private router: Router
    ) {
        this._runUpdates = !environment.mobile;
        this._updateInterval = 15000;
        this._routerSubscription = this.router.events.subscribe(event => this.onRouteChange(event));

        // Der Service muss als globale Variable gesetzt werden, damit der Intervall-Callback darauf zugreifen kann
        _autoUpdater = this;
    }

    public get type(): string {
        return this._updateType;
    }

    public get updateTime(): Date | null {
        return this.getService(this._updateType).lastLoaded;
    }

    onRouteChange(event: Event) {
        if (event instanceof NavigationStart) {
            this.stop();
        } else if (event instanceof NavigationEnd) {
            this.start(this._types[event.url]);
        }
    }

    ngOnDestroy() {
        this.stop();
        this._routerSubscription.unsubscribe();
    }

    start(type: string) {
        if (!this._runUpdates || !type) {
            return;
        }
        this._updateType = type;
        this._intervalSubscription = interval(this._updateInterval)
            .subscribe(this.fetchResources);
    }

    stop() {
        if (this._intervalSubscription) {
            this._intervalSubscription.unsubscribe();
        }
    }

    async fetchResources() {
        const time = _autoUpdater.updateTime;
        if (!time) {
            console.log('Keine Zeit vorhanden - AutoUpdate wird übersprungen');
            return;
        }

        const type = _autoUpdater.type;
        moment.locale('de');
        const updateTime = moment(time).format('YYYY-MM-DDTLTS');
        const typeQuery = '&type=' + type;

        const fetchUrl = '/v1/fleet/update?time=' + updateTime + typeQuery;
        const response = await sendGet(_autoUpdater.http, fetchUrl).toPromise()
            .catch((e: HttpErrorResponse) => {
                reportError(e);
            }) as UpdateResponse;

        const service = _autoUpdater.getService(type);
        if (response) {
            _autoUpdater.update(response, service);
        }
    }

    getService(key): AutoUpdate {
        switch (key) {
            case 'fleetLocation':
                return this.locations;
            case 'fleetGroup':
                return this.groups;
            case 'fleetIssue':
                return this.issue;
            case 'fleetVehicle':
                return this.vehicles;
        }
    }

    update(updateResponse: UpdateResponse, service: AutoUpdate) {
        const data = updateResponse[this.type];
        data.created.map((entity: any) => {
            service.add(entity);
        });
        data.updated.map((entity: any) => {
            service.set(entity);
        });
        data.deleted.map((idEntity: number) => {
            service.remove(idEntity);
        });
        service.lastLoaded = new Date();
    }

}
