import {Component, OnDestroy, OnInit} from '@angular/core';
import {Vehicle} from '../../model/vehicle.model';
import {ActivatedRoute, Router} from '@angular/router';
import {VehiclesService} from '../../service/vehicles/vehicles.service';
import {StateService} from '../../service/state/state.service';
import {LoadingService} from '../../../../shared/service/loading/loading.service';
import {State} from '../../model/state.model';
import {Location} from '@angular/common';
import {Subscription} from 'rxjs';
import {ProtocolService} from '../../../protocol/service/protocol.service';
import {AlertsService} from 'nb-form';
import {environment} from '../../../../../../environments/environment';
import {StressTestService} from '../../../protocol/service/stress-test.service';

@Component({
    selector: 'app-vehicle-simple',
    templateUrl: './vehicle-simple.component.html',
    styleUrls: ['./vehicle-simple.component.scss'],
})
export class VehicleSimpleComponent implements OnInit, OnDestroy {

    vehicle: Vehicle;

    state: State;

    id: number;

    private updateSub: Subscription;

    constructor(
        private route: ActivatedRoute,
        protected vehicles: VehiclesService,
        protected states: StateService,
        private loading: LoadingService,
        protected location: Location,
        private protocols: ProtocolService,
        protected alert: AlertsService,
        private router: Router,
        private stressTestService?: StressTestService,
    ) {
    }

    async ngOnInit() {
        await this.loading.show();
        await this.states.loadList();
        this.id = parseInt(this.route.snapshot.paramMap.get('id'), 10);

        if (!!this.id) {
            if (environment.mobile) {
                await this.vehicles.loadList();
            }
            this.vehicle = this.vehicles.get(this.id);
            if (!this.vehicle && !environment.mobile) {
                this.vehicle = await this.vehicles.loadOneFromServer(this.id).catch(async (e) => {
                    this.alert.toast({message: 'Fehler beim Fahrzeug laden', duration: 2000});
                    throw e;
                });
            }
        }
        if (this.vehicle) {
            this.state = this.states.get(this.vehicle.fleetStatus);
        }
        await this.loading.hide();

        this.updateSub = this.vehicles.vehiclesChanged.subscribe((vehicles: Vehicle[]) => {
            const updatedVehicle = vehicles.find((vehicle: Vehicle) => vehicle.id === this.id);
            if (updatedVehicle) {
                this.vehicle = updatedVehicle;
                this.state = this.states.get(this.vehicle.fleetStatus);
            }
        });
    }

    async clickReset() {
        const confirm = await this.alert.confirm({
            header: 'Änderungen verwerfen?',
            text: 'Alle Änderungen die seit dem letzten synchronisieren an diesem Fahrzeug gemacht wurden, ' +
                'werden <b>unwiderruflich gelöscht</b>.<br><br>Das Fahrzeug wird wieder zur Bearbeitung freigegeben.'
        }).catch(e => e);
        if (!confirm) {
            return;
        }
        this.reset();
    }

    async reset() {
        await this.loading.show();
        this.vehicle.needSync = false;
        this.vehicle.aspiredFleetStatus = undefined;
        await this.protocols.removeByVehicleId(this.vehicle.id);
        this.vehicles.set(this.vehicle);
        setTimeout(() => location.reload(), 2000);
    }

    ngOnDestroy() {
        this.updateSub.unsubscribe();
    }

    goBack() {
        if (environment.mobile) {
            this.router.navigate(['/tabs/vehicles']);
        } else {
            console.log(this.router);
            this.router.navigate(['/vehicles']);
        }
    }

    async clickCopyAll() {
        await this.loading.show();
        await this.router.navigate(['/tabs/vehicles']);
        await this.stressTestService.cloneProtocol(this.vehicle.id, this.vehicle.aspiredFleetStatus);
        window.location.reload();
    }

    get production() {
        return environment.production;
    }
}
