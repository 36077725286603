import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {LoadingService} from 'src/app/module/shared/service/loading/loading.service';
import {ExportStatus} from './export-status';
import {ToastService} from 'src/app/module/shared/service/toast/toast.service';
import {saveAs} from 'file-saver';
import {reportError} from '../../../../shared/error/sentry.error-handler';
import {sendGet} from '../../../../shared/function/api-url.function';

@Injectable({
    providedIn: 'root'
})
export class ExportService {

    private _idExport: number;

    constructor(private http: HttpClient, private loading: LoadingService, private toast: ToastService) { }

    async startExport(exportIds: number[], entityType = 'vehicle') {
        console.log('Start', exportIds);
        await this.loading.show();
        this.loading.setMessage('Bitte warten... </br>Der Export wird durchgeführt');
        const url = '/v1/fleet/csv-export';
        const data = {
            entity: entityType,
            ids: exportIds.join(','),
            // Wenn der Status auf true gesetzt wird, so wird der Export direkt angestoßen
            status: true
        };

        const response = await this.http.post(url, data)
            .toPromise()
            .catch((e: HttpErrorResponse) => {
                console.log('Error: ', e);
            }) as any;

        this._idExport = response.id;
        await this.getExport();
    }

    async getExport() {
        console.log('Export');
        if (!this._idExport) {
            throw new Error('Es wurde kein Export gefunden, der abgeholt werden kann');
        }

        const url = '/v1/fleet/csv-export/' + this._idExport;
        const response = await sendGet(this.http, url).toPromise()
            .catch((e: HttpErrorResponse) => {
                reportError(e);
                return e;
            }) as any;
        if (response instanceof Error || response instanceof HttpErrorResponse) {
            this.toast.httpError(response);
            this.loading.hide();
            return;
        }

        const status = response.status;
        if (status === ExportStatus.New || status === ExportStatus.Pending || status === ExportStatus.Progress) {
            setTimeout(async () => {
                await this.getExport();
            }, 2000);
        } else if (status === ExportStatus.Success) {
            const dataUrl = response.data;
            this.loading.hide();
            saveAs(dataUrl, 'export.csv');
        } else if (status === ExportStatus.Error) {
            this.toast.error('Fehler bei der Anfrage, bitte versuchen Sie den Export erneut');
            this.loading.hide();
        }
    }

}
