import {environment} from '../../../../environments/environment';
import {from, Observable, throwError} from 'rxjs';
import {map, switchMap, tap} from 'rxjs/operators';
import {HttpClient, HttpErrorResponse, HttpResponse} from '@angular/common/http';

export const STORAGE_KEY_EVENT_CODE = 'netfleet.eventCode';
export const FETCH_API_URL_ERROR = 99;

export function fetchApiUrl(): Observable<string> {
    if (!environment.mobile) {
        return from(Promise.resolve(environment.apiUrl));
    }
    const storageEventCode = getEventCode();
    const storageApiUrl = storageEventCode.apiUrl;
    if (storageApiUrl === null && (!storageEventCode || JSON.stringify(storageEventCode) === '{}')) {
        throw new HttpErrorResponse({
            error: 'Es wurde kein Gutschein-Code eingegeben!',
            status: FETCH_API_URL_ERROR,
            statusText: 'Es wurde kein Gutschein-Code eingegeben.',
            url: environment.apiUrl
        });
    } else if (!storageApiUrl || storageApiUrl === 'undefined') {
        return from(fetch(environment.apiUrl + '?code=' + storageEventCode.eventCode)).pipe(
            map((response) => {
                if (response.status !== 200) {
                    throw new Error(response.statusText);
                }
                return response;
            }),
            switchMap(r => r.json()),
            map(data => data.data.url),
            tap(apiUrl => localStorage.setItem(STORAGE_KEY_EVENT_CODE, JSON.stringify({
                apiUrl,
                eventCode: storageEventCode.eventCode
            })))
        );
    }
    return from(Promise.resolve(storageApiUrl));
}

export function sendGet<T>(http: HttpClient, url: string, options?: any): Observable<T> {
    return fetchApiUrl().pipe(
        switchMap(apiUrl => http.get(apiUrl + url, options)),
        map((response: any) => response as T)
    );
}

export function sendPost<T>(http: HttpClient, url: string, data: any, options?: any): Observable<T> {
    return fetchApiUrl().pipe(
        switchMap(apiUrl => http.post(apiUrl + url, data, options)),
        map((response: any) => response as T)
    );
}

export function sendPatch<T>(http: HttpClient, url: string, data: any, options?: any): Observable<T> {
    return fetchApiUrl().pipe(
        switchMap(apiUrl => http.patch(apiUrl + url, data, options)),
        map((response: any) => response as T)
    );
}

export function sendDelete<T>(http: HttpClient, url: string, data?: any, options?: any): Observable<T> {
    return fetchApiUrl().pipe(
        switchMap(apiUrl => http.delete(apiUrl + url, {
            ...options,
            body: data
        })),
        map((response: any) => response as T)
    );
}

export function getEventCode() {
    const eventCode = localStorage.getItem(STORAGE_KEY_EVENT_CODE);
    if (!eventCode) {
        return {} as { apiUrl: string, eventCode: string };
    }
    return JSON.parse(eventCode) as { apiUrl: string, eventCode: string };
}
