import {Component, Input} from '@angular/core';
import {environment} from '../../../../../environments/environment';
import {AuthService} from '../../../page/account/service/auth/auth.service';

@Component({
    selector: 'app-navigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent {

    @Input() disable = false;

    constructor(private auth: AuthService) {
    }

    get mobile() {
        return environment.mobile;
    }

    get disabled() {
        return this.disable || !this.auth.isAuthenticated();
    }

}
