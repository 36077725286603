import {Component, Input, OnInit} from '@angular/core';
import {StateService} from '../../../service/state/state.service';
import {environment} from '../../../../../../../environments/environment';
import {Vehicle} from '../../../model/vehicle.model';
import * as _moment from 'moment';
const moment = _moment;

@Component({
  selector: 'app-protocols',
  templateUrl: './protocols.component.html',
  styleUrls: ['./protocols.component.scss'],
})
export class ProtocolsComponent implements OnInit {

  @Input() vehicle: Vehicle;

  constructor(public states: StateService) { }

  ngOnInit() {}

  get protocols() {
    if (!this.vehicle || !Array.isArray(this.vehicle.protocols)) {
      return [];
    }
    return this.vehicle.protocols.sort((a, b) => {
      if (moment(a.createdAt) > moment(b.createdAt)) {
        return -1;
      }
      if (moment(a.createdAt) < moment(b.createdAt)) {
        return 1;
      }
      return 0;
    });
  }

  getProtocolRoute(protocol: number) {
    return [environment.mobile ? '/tabs/protocol/' + protocol : '/protocol/' + protocol];
  }

}
