import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {VehiclesTableComponent} from './module/page/vehicles/component/table/table.component';
import {VehicleComponent} from './module/page/vehicles/component/vehicle/vehicle.component';
import {AuthGuard} from './module/page/account/guard/auth/auth.guard';

const routes: Routes = [
    {
        path: '',
        loadChildren: () => import('./module/tabs/tabs.module').then(m => m.TabsPageModule),
    },
    {
        path: 'account',
        children: [
            {
                path: '',
                loadChildren: () => import('./module/page/account/account.module').then(m => m.AccountPageModule)
            }
        ]
    },
    {
        path: 'timeline',
        loadChildren: () => import('./module/page/timeline/timeline.module').then(m => m.TimelinePageModule),
        canActivate: [AuthGuard],
        data: {
            route: 'v1_fleet_vehicle_issue_list'
        }
    },
    {
        path: 'issues',
        loadChildren: () => import('./module/page/issues/issues.module').then(m => m.IssuesPageModule),
        canActivate: [AuthGuard],
        data: {
            route: 'v1_fleet_vehicle_issue_list'
        }
    },
    {
        path: 'vehicles',
        component: VehiclesTableComponent,
        canActivate: [AuthGuard],
        data: {
            route: 'v1_fleet_vehicle_list'
        }
    },
    {
        path: 'vehicles/:id',
        component: VehicleComponent,
        canActivate: [AuthGuard],
        data: {
            route: 'v1_fleet_vehicle_show'
        }
    },
    {
        path: 'vehicles/:vehicle/state/:state',
        loadChildren: () => import('./module/page/protocol/protocol.module').then(m => m.ProtocolPageModule),
        canActivate: [AuthGuard],
        data: {
            route: 'v1_fleet_vehicle_protocol_get'
        }
    },
    {
        path: 'protocol',
        loadChildren: () => import('./module/page/protocol/protocol.module').then(m => m.ProtocolPageModule),
        canActivate: [AuthGuard],
        data: {
            route: 'v1_fleet_vehicle_protocol_show'
        }
    },
    {
        path: 'locations',
        loadChildren: () => import('./module/page/locations/locations.module').then(m => m.LocationsPageModule),
        canActivate: [AuthGuard],
        data: {
            route: 'v1_fleet_location_list'
        }
    },
    {
        path: 'groups',
        loadChildren: () => import('./module/page/groups/groups.module').then(m => m.GroupsPageModule),
        canActivate: [AuthGuard],
        data: {
            route: 'v1_fleet_group_list'
        }
    },
    {
        path: 'event-code',
        loadChildren: () => import('./module/page/event-code/event-code.module').then(m => m.EventCodePageModule)
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy'})
    ],
    exports: [RouterModule]
})

export class AppRoutingModule {
}
