import {Component, Input, OnInit} from '@angular/core';
import {Vehicle} from '../../../vehicles/model/vehicle.model';
import {VehicleIssueModalComponent} from '../vehicle-issue-modal/vehicle-issue-modal.component';
import {ModalController} from '@ionic/angular';
import {FormType} from '../../../../shared/model/form-type';
import * as _moment from 'moment';
const moment = _moment;

@Component({
  selector: 'app-vehicle-issues',
  templateUrl: './vehicle-issues.component.html',
  styleUrls: ['./vehicle-issues.component.scss'],
})
export class VehicleIssuesComponent implements OnInit {

  @Input() vehicle: Vehicle;

  constructor(
      private modalController: ModalController,
  ) { }

  ngOnInit() {
  }

  async addIssue() {
    const modal = await this.modalController.create({
      component: VehicleIssueModalComponent,
      componentProps: {
        title: 'Neuen Auftrag anlegen',
        type: FormType.New,
        vehicle: this.vehicle
      }
    });
    return await modal.present();
  }

    async editIssue(vehicleIssue) {
      const modal = await this.modalController.create({
        component: VehicleIssueModalComponent,
        componentProps: {
          title: 'Auftrag bearbeiten',
          type: FormType.Edit,
          vehicle: this.vehicle,
          vehicleIssue,
        }
      });
      return await modal.present();
    }
}
