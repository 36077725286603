import {Injectable} from '@angular/core';
import {deepCopy, Form} from 'nb-form';
import {Storage} from '@ionic/storage';
import {ProtocolDownloadService} from './protocol-download.service';
import {HttpErrorResponse} from '@angular/common/http';
import {State} from '../../vehicles/model/state.model';
import {Vehicle} from '../../vehicles/model/vehicle.model';
import {StateService} from '../../vehicles/service/state/state.service';
import {FileAdapterService} from './file-adapter.service';
import {AuthService} from '../../account/service/auth/auth.service';
import {Protocol} from '../model/protocol.model';
import {LoadingService} from '../../../shared/service/loading/loading.service';

@Injectable({
    providedIn: 'root'
})
export class ProtocolService {

    private _protocols: {
        [idVehicle: number]: Form
    } = {};

    private _readonlyProtocols: {
        [protocolId: number]: Form
    } = {};

    constructor(
        private state: StateService,
        private storage: Storage,
        private download: ProtocolDownloadService,
        private fileAdapter: FileAdapterService,
        private session: AuthService,
        private loading: LoadingService
    ) {
    }

    async getReadonly(protocolId: number): Promise<Form> {
        // from cache
        if (this._readonlyProtocols[protocolId]) {
            return this._readonlyProtocols[protocolId];
        }
        // from storage
        const protocol = await this.storage.get(getStorageKeyProtocolReadonly(protocolId)).then(data => data as Form);
        if (protocol) {
            this._readonlyProtocols[protocolId] = protocol;
            return protocol as Form;
        }
        // from server
        return await this.getReadonlyFromServer(protocolId);
    }

    async getReadonlyFromServer(protocolId): Promise<Form> {
        const result = await this.download.show(protocolId).catch(e => e);
        if (result instanceof Error || result instanceof HttpErrorResponse) {
            throw result;
        }
        this._readonlyProtocols[protocolId] = result;
        await this.storage.set(getStorageKeyProtocolReadonly(protocolId), result);
        return result;
    }

    async getOneOrCreate(vehicle: Vehicle, state: State) {
        if (!this._protocols[vehicle.id]) {
            let protocol = await this.getOneFromStorage(vehicle.id);
            if (!protocol) {
                protocol = await this.create(vehicle, state);
                protocol.data.person = {label: this.session.userLabel};
            }
            this._protocols[vehicle.id] = protocol;
        }
        return this._protocols[vehicle.id];
    }

    async create(vehicle: Vehicle, state: State) {
        const form = new Form();
        const createForm = await this.download.create(vehicle, state).toPromise();
        form.schema = createForm.schemata;
        form.data = {
            ...createForm.data,
            state: deepCopy(state)
        };
        return form;
    }

    async save(idVehicle: number, form: Form) {

        if (!form.id) {
            this._protocols[idVehicle] = form;
            await this.storage.set(getStorageKeyProtocol(idVehicle), form);
            return;
        }

        await this.storage.set(getStorageKeyProtocolReadonly(form.id), form);
        await this.storage.remove(getStorageKeyProtocol(idVehicle));
    }

    async removeByVehicleId(idVehicle: number) {
        if (this._protocols[idVehicle]) {
            delete this._protocols[idVehicle];
        }
        await this.storage.remove(getStorageKeyProtocol(idVehicle));
        await this.fileAdapter.cleanStorage(idVehicle);
    }

    async remove(protocolId: number) {
        if (this._readonlyProtocols[protocolId]) {
            delete this._readonlyProtocols[protocolId];
        }
        await this.storage.remove(getStorageKeyProtocolReadonly(protocolId));
    }

    async getOneFromStorage(vehicleId: number): Promise<Form> {
        return await this.storage.get(getStorageKeyProtocol(vehicleId)) as Form;
    }

    /**
     * Erstellt eine Kopie eines Readonly Protokolls und gibt sie zurück
     */
    async editReadonly(vehicleId: number, formReadonly: Protocol) {
        if (this._protocols[vehicleId]) {
            return this._protocols[vehicleId];
        }
        const storageForm = await this.getOneFromStorage(vehicleId);
        if (storageForm) {
            return storageForm;
        }
        const newForm = new Form();
        newForm.data = deepCopy(formReadonly.data);
        delete newForm.data.signature;
        newForm.schema = formReadonly.schema;
        await this.save(vehicleId, newForm);

        const files = await this.fileAdapter.downloadAll(formReadonly);
        this.loading.setMessage('Kopiere Protokoll ...');
        for (const elementId of Object.keys(files)) {
            await this.fileAdapter.writeToVehicle(vehicleId, elementId, files[elementId]);
        }

        return newForm;
    }
}

export function getStorageKeyProtocol(vehicleId: number) {
    return 'nf.protocol.' + vehicleId;
}

function getStorageKeyProtocolReadonly(protocolId: number) {
    return 'nf.protocol.readonly.' + protocolId;
}
