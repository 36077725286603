import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../../../environments/environment';
import {State} from '../../model/state.model';
import {Storage} from '@ionic/storage';
import {Vehicle} from '../../model/vehicle.model';
import {map} from 'rxjs/operators';
import {sendGet, sendPatch} from '../../../../shared/function/api-url.function';

@Injectable({
    providedIn: 'root'
})
export class StateService {

    private _states: State[] = [];

    constructor(private http: HttpClient, private storage: Storage) {
    }

    get(id: number) {
        const state = this._states.find(s => s.id === id);
        if (!state) {
            return {} as State;
        }
        return state;
    }

    get states() {
        return this._states;
    }

    async loadFromStorage(): Promise<State[]> {
        const states = await this.storage.get('nf.states');
        if (!Array.isArray(states)) {
            return [];
        }
        this._states = states;
        return this._states;
    }

    async loadFromServer(): Promise<State[]> {
        const response: any = await sendGet(this.http, '/v1/fleet/state').toPromise();
        this._states = response.data as State[];
        await this.storage.set('nf.states', this._states);
        return this._states;
    }

    async loadList(): Promise<State[]> {
        if (Array.isArray(this._states) && this._states.length > 0) {
            return this._states;
        }
        if (environment.mobile) {
            await this.loadFromStorage();
            if (Array.isArray(this._states) && this._states.length > 0) {
                return this._states;
            }
        }
        return await this.loadFromServer();
    }

    uploadStateChange(vehicle: Vehicle) {
        return sendPatch(this.http, '/v1/fleet/vehicle/' + vehicle.id + '/state', {
            status: vehicle.aspiredFleetStatus
        }).pipe(
            map((response: any) => response.data as Vehicle)
        ).toPromise();
    }
}
