import {EventEmitter, Injectable} from '@angular/core';
import {Form} from 'nb-form';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {ProtocolService} from './protocol.service';
import {Storage} from '@ionic/storage';
import {FileModel} from '../model/file.model';
import {LoadingService} from '../../../shared/service/loading/loading.service';
import {FileAdapterService} from './file-adapter.service';
import {ProtocolExportService} from './export.service';
import {sendPost} from '../../../shared/function/api-url.function';

@Injectable({
    providedIn: 'root'
})
export class ProtocolUploadService {

    dataChanged = new EventEmitter<number>();

    private protocol: Form;
    private vehicleId: number;
    private stateId: number;

    constructor(
        private http: HttpClient,
        private protocolService: ProtocolService,
        private storage: Storage,
        private loading: LoadingService,
        private fileAdapter: FileAdapterService,
        private protocolExportService: ProtocolExportService
    ) {
    }

    async store(protocol: Form) {
        this.protocol = protocol;
        if (!this.validate()) {
            throw new Error('Cannot update invalid Form data');
        }
        const data = getData(protocol);
        this.vehicleId = data.vehicle.id;
        this.stateId = data.state.id;
        const url = '/v1/fleet/vehicle/' + this.vehicleId + '/protocol';
        const response = await sendPost(this.http, url, { data: { data, schemata: protocol.schema, status: this.stateId } }).pipe(
            map(r => r as ProtocolStoreResponse)
        ).toPromise();
        protocol.id = response.id;
        protocol.readonly = true;
        await this.storeFiles();
        await this.initPdfExport(protocol.id);
        await this.protocolService.removeByVehicleId(this.vehicleId);
        this.dataChanged.emit(response.id);
        return protocol;
    }

    private validate() {
        return this.protocol.data.vehicle && this.protocol.data.vehicle.id && this.protocol.data.state && this.protocol.data.state.id;
    }

    private async storeFiles() {
        const files = await this.getFilesFromStorage();
        const storedFiles = [];
        for (const file of files) {
            this.loading.setMessage('Lade Dateien hoch ' + (storedFiles.length + 1) + ' von ' + files.length);
            const storedFile = await this.storeFile(file);
            storedFiles.push(storedFile);
        }
    }

    private async storeFile(file: FileModel) {
        const url = '/v1/fleet/protocol/' + this.protocol.id + '/file';
        const response = await sendPost(this.http, url, {
            data: file
        }).pipe(
            map(r => r as ProtocolFileStoreResponse)
        ).toPromise();
        file.id = response.id;
        file.protocolId = this.protocol.id;
        return file;
    }

    private async initPdfExport(protocolId: number) {
        return this.protocolExportService
            .triggerExport(protocolId)
            .toPromise()
            .catch(e => e);
    }

    private async getFilesFromStorage(): Promise<FileModel[]> {
        const fileMap = await this.fileAdapter.getAllFiles(this.vehicleId);
        let files = [];
        Object.values(fileMap).map(fieldFiles => {
            files = files.concat(fieldFiles);
        });
        return files;
    }
}

export interface ProtocolStoreResponse {
    id: number;
}

interface ProtocolFileStoreResponse {
    id: number;
}

function getData(p: Form) {
    const data = p.data;
    data.damages = formDamages(data);
    console.log(data);
    return data;
}

function formDamages(data: any) {
    const damages = [];
    if(!data || !Array.isArray(data.damages)) {
        return [];
    }

    for(const damage of data.damages) {
        damages.push({
            ...damage,
            data: {
                ...damage.data,
                position: formDamagePosition(damage)
            }
        });
    }

    return damages;
}

/**
 * Wirf alles raus, außer das ausgewählte Bild
 */
function formDamagePosition(damage: any) {
    const position = !!damage.data && !!damage.data.position ? damage.data.position : {};
    if(!position.where) {
        return position;
    }

    const formedPosition = {where: position.where};
    formedPosition[position.where] = position[position.where];

    return formedPosition;
}