import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {State} from '../../../model/state.model';
import {environment} from '../../../../../../../environments/environment';
import {Vehicle} from '../../../model/vehicle.model';
import {ToastService} from '../../../../../shared/service/toast/toast.service';
import {LoadingService} from '../../../../../shared/service/loading/loading.service';
import {Router} from '@angular/router';
import {VehiclesService} from '../../../service/vehicles/vehicles.service';
import {AlertsService} from 'nb-form';
import {StateService} from '../../../service/state/state.service';
import {Subscription} from 'rxjs';
import {reportError} from '../../../../../shared/error/sentry.error-handler';

@Component({
    selector: 'app-possible-states',
    templateUrl: './possible-states.component.html',
    styleUrls: ['./possible-states.component.scss'],
})
export class PossibleStatesComponent implements OnInit, OnDestroy {

    @Input() vehicle: Vehicle;

    @Input() state: State;

    @Output() onReset = new EventEmitter();

    private changeSub: Subscription;

    constructor(
        private toast: ToastService,
        private loading: LoadingService,
        private router: Router,
        private vehicles: VehiclesService,
        private alert: AlertsService,
        public states: StateService
    ) {
    }

    ngOnInit() {
        this.changeSub = this.vehicles.vehiclesChanged.subscribe((changed: Vehicle[]) => {
            const vehicle = changed.find(v => v.id === this.vehicle.id);
            if (!vehicle) {
                return;
            }
            this.vehicle = vehicle;
            this.state = this.states.get(vehicle.fleetStatus);
        });
    }

    ngOnDestroy(): void {
        this.changeSub.unsubscribe();
    }

    get possibleStates() {
        if (!!environment.mobile){
            return this.state.possibleStates.filter(s => s !== this.vehicle.fleetStatus);
        }
        return this.state.possibleStatesDesktop.filter(s => s !== this.vehicle.fleetStatus);
    }

    async clickChangeState(state: State) {
        if (!state.protocol) {
            return await this.setStateSimple(state);
        }
        await this.openForm(state);
    }

    async setStateSimple(state: State) {
        const confirm = await this.alert.confirm({
            text: this.getStateConfirmMessage(state),
            header: 'Status ändern?'
        }).catch(e => e);
        if (!confirm) {
            return;
        }
        if (!environment.mobile) {
            return await this.setStateDesktop(state);
        }
        this.setStateMobile(state);
    }

    private async setStateDesktop(state: State) {
        await this.loading.show();
        this.vehicle.aspiredFleetStatus = state.id;
        this.vehicle = await this.states.uploadStateChange(this.vehicle).catch(async e => {
            await this.loading.hide();
            this.toast.error('Fehler beim Status setzen');
            this.vehicle.aspiredFleetStatus = undefined;
            reportError(e);
            throw e;
        });
        this.vehicles.set(this.vehicle);
        await this.loading.hide();
        this.toast.success('Status wurde geändert');
    }

    private async setStateMobile(state: State) {
        await this.loading.show();
        this.vehicle.carNumber = 'Maxim Uhlemann'; // todo warum??
        this.vehicle.aspiredFleetStatus = state.id;
        this.vehicle.needSync = true;
        this.vehicles.set(this.vehicle);
        await this.loading.hide();
        this.toast.success('Status wurde geändert');
    }

    async openForm(state: State) {
        const confirm = await this.alert.confirm({
            text: this.getStateConfirmMessage(state) + '<br><br><b>Für diesen Status muss ein Protokoll erstellt werden.</b>',
            header: 'Status ändern?'
        }).catch(e => e);
        if (!confirm) {
            return;
        }
        const url = '/vehicles/' + this.vehicle.id + '/state/' + state.id;
        await this.router.navigate([(environment.mobile ? '/tabs/' : '') + url]);
    }

    clickReset() {
        this.onReset.emit();
    }

    private getStateConfirmMessage(state: State) {
        let text = 'Soll der Status dieses Fahrzeugs von <b>' + this.state.name + '</b> zu <b>' + state.name + '</b> geändert werden?';
        if (this.state.description) {
            text += ' <br><br>';
            text += this.state.name + ': ' + this.state.description;
        }
        if (state.description) {
            text += ' <br><br>';
            text += state.name + ': ' + state.description;
        }
        return text;
    }
}
