import {Form} from 'nb-form';
import {Vehicle} from '../../vehicles/model/vehicle.model';
import {State} from '../../vehicles/model/state.model';

export class Protocol extends Form {

    data: {
        vehicle?: Vehicle;
        state?: State;
        damage?: any;
        signature?: {
            svg: string;
            base64: string;
            date: Date;
        };
        [key: string]: any;
    };

    files: ProtocolFileRef[];
}

export interface ProtocolFileRef {
    id: number;
    name: string;
    formElement: string;
    createdAt: Date;
    createdBy: string;
}
