import {EventEmitter, Injectable, Output} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {ToastService} from 'src/app/module/shared/service/toast/toast.service';
import {Group} from '../../vehicles/model/group.model';
import {AutoUpdate} from 'src/app/module/shared/model/autoupdate';
import {reportError} from '../../../shared/error/sentry.error-handler';
import {sendDelete, sendGet, sendPatch, sendPost} from '../../../shared/function/api-url.function';

@Injectable({
    providedIn: 'root'
})
export class GroupsService implements AutoUpdate {

    private maxPages = 0;

    private limit = 25;

    private _groups: Group[] = [];

    private _lastLoaded: Date = null;

    @Output()
    onChange = new EventEmitter<Group[]>();

    constructor(private http: HttpClient, private toast: ToastService) {
        this.initStorageEvent();
    }

    public get lastLoaded(): Date {
        return this._lastLoaded;
    }

    public set lastLoaded(date: Date) {
        this._lastLoaded = date;
    }

    get(id: number) {
        return this._groups.find(group => group.id === id);
    }

    async load(): Promise<Group[]> {
        let page = 1;
        this._groups = [];
        while (page <= this.maxPages || this.maxPages === 0) {
            await this.loadNextPage(page);
            page++;
        }
        this._lastLoaded = new Date();
        return this._groups;
    }

    private async loadNextPage(page: number) {
        const url = '/v1/fleet/group?page=' + page + '&limit=' + this.limit;

        const response = await sendGet(this.http, url).toPromise() as any;
        this._groups = this._groups.concat(response.data as Group[]);
        this.maxPages = response.countResultPages;
        return this._groups;
    }

    async store(groupData: any): Promise<boolean> {
        const response = await sendPost(this.http, '/v1/fleet/group', {group: groupData})
            .toPromise().catch((e: HttpErrorResponse) => {
                reportError(e);
                return e;
            }) as any;

        if (!(response instanceof HttpErrorResponse)) {
            this._groups.push(response.data);
            this.toast.success('Erfolgreich gespeichert');
            return true;
        }

        this.toast.error(this.parseErrors(response));
        return false;
    }

    async update(id: number, groupData: any) {
        const url = '/v1/fleet/group/' + id;
        const response = await sendPatch(this.http, url, {group: groupData})
            .toPromise().catch((e: HttpErrorResponse) => {
                reportError(e);
                return e;
            }) as any;

        if (!(response instanceof HttpErrorResponse)) {
            const element = this._groups.find((group: Group) => {
                return (group.id === id);
            });
            const newElement = response.data as Group;
            const indexOf = this._groups.indexOf(element);
            this._groups[indexOf] = newElement;
            this.toast.success('Erfolgreich gespeichert');
            return true;
        }

        this.toast.error(this.parseErrors(response));
        return false;
    }

    async delete(id) {
        const url = '/v1/fleet/group/' + id;
        const response = await sendDelete(this.http, url)
            .toPromise().catch((e: HttpErrorResponse) => {
                reportError(e);
                return e;
            }) as any;

        if (!(response instanceof HttpErrorResponse)) {
            this.remove(id);
            this.toast.success('Erfolgreich gelöscht');
            return true;
        }

        this.toast.error(this.parseErrors(response));
        return false;
    }

    parseErrors(e: HttpErrorResponse): string {
        const errors = e.error.errors;
        let message = '';

        errors.map((error) => {
            const tempString = 'Feld' + error.field + ': ' + error.message;
            message += tempString + '<br>';
        });
        return message;
    }

    add(group: Group) {
        const elementExists = this.get(group.id);
        if (!elementExists) {
            this._groups.push(group);
            this.setUpdatedGroups();
            this.onChange.emit(this._groups);
        }
    }

    set(group: Group) {
        const element = this.get(group.id);

        if (element) {
            const index = this._groups.indexOf(element);
            this._groups[index] = group;
            this.setUpdatedGroups();
            this.onChange.emit(this._groups);
        }
    }

    remove(idGroup: number) {
        const group = this.get(idGroup);

        if (group) {
            const index = this._groups.indexOf(group);
            this._groups.splice(index, 1);
            this.setUpdatedGroups();
            this.onChange.emit(this._groups);
        }
    }

    initStorageEvent() {
        window.addEventListener('storage', (event) => this.onStorageUpdate(event), false);
    }

    onStorageUpdate(event) {
        if (event.key === 'group-update') {
            this._groups = JSON.parse(event.newValue);
            console.log('NewGroups: ', this._groups);
            this.onChange.emit(this._groups);
        }
    }

    setUpdatedGroups() {
        localStorage.setItem('group-update', JSON.stringify(this._groups));
    }

}
