import {Component, EventEmitter, Input, Output} from '@angular/core';
import {StateService} from '../../../service/state/state.service';
import {State} from '../../../model/state.model';

@Component({
    selector: 'app-state-button',
    templateUrl: './state-button.component.html',
    styleUrls: ['./state-button.component.scss'],
})
export class StateButtonComponent {

    @Input() state: number;
    @Output() onClick = new EventEmitter<State>();

    constructor(public states: StateService) {
    }

    click() {
        this.onClick.emit(this.states.get(this.state));
    }
}
