import {Injectable} from '@angular/core';
import {reportErrorMessage} from '../../../../shared/error/sentry.error-handler';
import {AclModel} from '../../../../acc/model/acl.model';

const STORAGE_KEY = 'session';

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    private _session: Session;

    constructor() {
    }

    isAuthenticated() {
        if (this.session === undefined && isAuthenticated()) {
            try {
                this.session = JSON.parse(localStorage.getItem(STORAGE_KEY));
                return true;
            } catch (e) {
                reportErrorMessage('Error parsing session', localStorage.getItem(STORAGE_KEY));
                return false;
            }
        }
        return !!this.session;
    }

    get session() {
        return this._session;
    }

    set session(v: Session) {
        this._session = v;
        if (v === null) {
            localStorage.removeItem(STORAGE_KEY);
            return;
        }
        localStorage.setItem(STORAGE_KEY, JSON.stringify(v));
    }

    get token() {
        return this.session.token;
    }

    get userLabel() {
        return this.session.userLabel;
    }

    get userId() {
        return this.session.id;
    }

    get username() {
        return this.session.username;
    }

    get acl() {
        return this.session.acl;
    }
}

export interface Session {
    token: string;
    userLabel: string;
    id: number;
    username: string;
    acl: AclModel[];
}

export function isAuthenticated() {
    return !!localStorage.getItem(STORAGE_KEY);
}
