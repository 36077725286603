import {Injectable} from '@angular/core';
import {Storage} from '@ionic/storage';
import {getStorageKeyProtocol, ProtocolService} from './protocol.service';
import {FileAdapterService, getFileKey} from './file-adapter.service';
import {Protocol} from '../model/protocol.model';
import {FileModel} from '../model/file.model';
import {VehiclesService} from '../../vehicles/service/vehicles/vehicles.service';
import {deepCopy} from 'nb-form';
import {Vehicle} from '../../vehicles/model/vehicle.model';
import {LoadingService} from '../../../shared/service/loading/loading.service';
import {min} from 'rxjs/operators';
import {environment} from '../../../../../environments/environment';
import {StateService} from '../../vehicles/service/state/state.service';

@Injectable({
    providedIn: 'root'
})
export class StressTestService {

    constructor(
        private storage: Storage,
        private fileAdapterService: FileAdapterService,
        private vehiclesService: VehiclesService,
        private protocolService: ProtocolService,
        private loading: LoadingService,
        private statesService: StateService
    ) {
    }

    async cloneProtocol(vehicleId: number, aspiredFleetStatus: number) {
        const protocol = await this.storage.get(getStorageKeyProtocol(vehicleId)) as Protocol;
        if (!protocol) {
            throw Error('Kein Protokoll');
        }
        const fileMap = await this.fileAdapterService.getAllFiles(vehicleId);
        await this.vehiclesService.loadList();
        const total = this.vehiclesService.vehicles.length - 1;
        for (const vehicle of this.vehiclesService.vehicles) {
            if (vehicle.id === vehicleId) {
                continue;
            }
            const count = this.vehiclesService.vehicles.indexOf(vehicle) + 1;
            this.loading.setMessage('Kopiere Fahrzeug ' + count + ' von ' + total);
            await this.cloneToVehicle(vehicle, aspiredFleetStatus, protocol, fileMap);
        }
        this.loading.setMessage('Kopieren beendet. Starte neu ...');
    }

    private async cloneToVehicle(
        vehicle: Vehicle,
        aspiredFleetStatus: number,
        protocol: Protocol,
        fileMap?: { [elementId: string]: FileModel[] }
    ) {
        const state = this.statesService.get(vehicle.fleetStatus);
        if (!Array.isArray(state.possibleStates) || state.possibleStates.indexOf(aspiredFleetStatus) < 0) {
            return;
        }
        vehicle.aspiredFleetStatus = aspiredFleetStatus;
        vehicle.needSync = true;
        this.vehiclesService.set(vehicle);

        const clone = deepCopy(protocol) as Protocol;
        clone.data.vehicle = vehicle;
        await this.protocolService.save(vehicle.id, clone);

        if (fileMap) {
            for (const elementId of Object.keys(fileMap)) {
                await this.storage.set(getFileKey(vehicle.id, elementId), fileMap[elementId]);
            }
        }
        await sleep(100);
    }

}

function sleep(millis: number) {
    return new Promise(resolve => setTimeout(resolve, millis));
}
