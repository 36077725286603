import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {VehiclesPage} from './component/vehicles/vehicles.page';
import {VehicleSimpleComponent} from './component/vehicle-simple/vehicle-simple.component';

const routes: Routes = [
  {
    path: '',
    component: VehiclesPage
  },
  {
    path: ':id',
    component: VehicleSimpleComponent
  },
  {
    path: ':vehicle/state/:state',
    loadChildren: () => import('../../page/protocol/protocol.module').then( m => m.ProtocolPageModule)
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class VehiclesPageRoutingModule {}
