import {Component, OnInit, ViewChild} from '@angular/core';
import {VehiclesService} from '../../service/vehicles/vehicles.service';
import {LoadingService} from '../../../../shared/service/loading/loading.service';
import {HttpErrorResponse} from '@angular/common/http';
import {ToastService} from '../../../../shared/service/toast/toast.service';
import {StateService} from '../../service/state/state.service';
import {Vehicle} from '../../model/vehicle.model';
import {IonInfiniteScroll, Platform} from '@ionic/angular';
import {Location} from '../../model/location.model';
import {Group} from '../../model/group.model';
import {AlertsService} from 'nb-form';
import {Insomnia} from '@ionic-native/insomnia/ngx';
import {reportError, reportErrorMessage} from '../../../../shared/error/sentry.error-handler';

@Component({
    selector: 'app-vehicles',
    templateUrl: './vehicles.page.html',
    styleUrls: ['./vehicles.page.scss'],
})
export class VehiclesPage implements OnInit {

    visibleVehicles: Vehicle[] = [];
    possibleLocations: Location[] = [];
    possibleGroups: Group[] = [];

    private searchQuery = '';
    searchState: string | number = '';
    searchLocation: string | number = '';
    searchGroup: string | number = '';
    searchNeedSync: string | boolean = '';
    searchResultsCount = 0;

    @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;
    private infiniteScrollPage = 1;
    private infiniteScrollPageSize = 30;

    constructor(
        public vehicles: VehiclesService,
        public states: StateService,
        private loading: LoadingService,
        private toast: ToastService,
        private alerts: AlertsService,
        private insomnia: Insomnia,
        private platform: Platform
    ) {
    }

    async ngOnInit() {
        await this.loading.show();
        await this.states.loadList()
            .catch(e => this.onError(e, 332));
        await this.vehicles.loadList()
            .then(vehicles => this.onLoadVehicles(vehicles))
            .catch(e => this.onError(e, 332));
        await this.loading.hide();
    }

    private onLoadVehicles(vehicles: Vehicle[]) {
        this.visibleVehicles = this.paginate(this.infiniteScrollPage);
        const locations = {};
        const groups = {};
        for (const vehicle of vehicles) {
            if (vehicle.fleetLocation) {
                locations[vehicle.fleetLocation.id] = vehicle.fleetLocation;
            }
            if (vehicle.fleetGroup) {
                groups[vehicle.fleetGroup.id] = vehicle.fleetGroup;
            }
        }
        this.possibleLocations = Object.values(locations);
        this.possibleGroups = Object.values(groups);
    }

    async clickSync() {
        await this.loading.show();
        if (!await this.upload()) {
            await this.loading.hide();
            return;
        }
        await this.download();
        await this.loading.hide();
        await this.toast.success('Fahrzeuge aktualisiert');
    }

    private async upload() {
        if (this.vehicles.unsyncedVehicles.length === 0) {
            return true;
        }
        this.startInsomnia();
        const unsyncedTotal = this.vehicles.unsyncedVehicles.length;
        this.loading.setMessage('Lade ausgefüllte Protokolle hoch ...');
        const uploadErrorsCount = await this.vehicles.uploadToServer();
        if (uploadErrorsCount > 0) {
            await this.loading.hide();
            const confirm = await this.alerts.confirm({
                header: 'Fehler beim Upload',
                text: (unsyncedTotal - uploadErrorsCount) + ' Fahrzeuge erfolgreich hochgeladen.<br><br>'
                    + uploadErrorsCount + ' Fahrzeuge konnten nicht hochgeladen werden.',
                textOk: 'Erneut versuchen'
            }).catch(e => e);
            if (!confirm) {
                this.stopInsomnia();
                return false;
            }
            await this.loading.show();
            return this.upload();
        }
        this.loading.setMessage('Bitte warten ...');
        this.stopInsomnia();
        return true;
    }

    private async download() {
        this.infiniteScrollPage = 1;
        await this.states.loadFromServer()
            .catch(e => this.onSyncError(e));
        this.loading.setMessage('Fahrzeuge werden geladen ...');
        await this.vehicles.loadFromServer()
            .then(vehicles => this.onLoadVehicles(vehicles))
            .catch(e => this.onSyncError(e));
    }

    private async onSyncError(e: HttpErrorResponse) {
        await this.loading.hide();
        await this.toast.httpError(e);
        throw e;
    }

    private async onError(e: any, code: number) {
        await this.loading.hide();
        await this.toast.error('Unbekannter Fehler ' + code);
        return e;
    }

    paginate(page: number) {
        this.infiniteScrollPage = page;
        if (!Array.isArray(this.vehicles.vehicles)) {
            return [];
        }
        const results = this.filter(this.vehicles.vehicles);
        this.searchResultsCount = results.length;
        // infinite scroll
        return results.slice((page - 1) * this.infiniteScrollPageSize, page * this.infiniteScrollPageSize);
    }

    private filter(vehicles: Vehicle[]) {
        let results = vehicles;
        // Filter nach NeedSync
        if (typeof this.searchNeedSync === 'boolean') {
            results = results.filter(v => !!v.needSync === this.searchNeedSync);
        }
        // filter nach Status
        if (typeof this.searchState === 'number') {
            results = results.filter(v => v.fleetStatus + '' === this.searchState + '');
        }
        // filter nach Standort
        if (typeof this.searchLocation === 'number') {
            results = results.filter(v => {
                if (this.searchLocation === 0 && !v.fleetLocation) {
                    return true;
                }
                return v.fleetLocation && (v.fleetLocation.id + '' === this.searchLocation + '');
            });
        }
        // filter nach Gruppe
        if (typeof this.searchGroup === 'number') {
            results = results.filter(v => {
                if (this.searchGroup === 0 && !v.fleetGroup) {
                    return true;
                }
                return v.fleetGroup && (v.fleetGroup.id + '' === this.searchGroup + '');
            });
        }
        // Filter nach Nummernschild
        if (this.searchQuery && this.searchQuery.length > 0) {
            results = results.filter(v => v.licence && v.licence.toLowerCase().indexOf(this.searchQuery.toLowerCase()) >= 0);
        }
        return results;
    }

    search(event) {
        this.searchQuery = event.detail.value;
        this.visibleVehicles = this.paginate(1);
        this.infiniteScroll.disabled = this.visibleVehicles.length >= this.searchResultsCount;
    }

    changeFilter() {
        this.visibleVehicles = this.paginate(1);
        this.infiniteScroll.disabled = this.visibleVehicles.length >= this.searchResultsCount;
    }

    resetFilter() {
        this.searchLocation = '';
        this.searchGroup = '';
        this.searchLocation = '';
        this.searchState = '';
        this.searchNeedSync = '';
        this.searchQuery = '';
        this.changeFilter();
    }

    scrollBottom(event) {
        setTimeout(() => {
            this.visibleVehicles = this.visibleVehicles.concat(this.paginate(this.infiniteScrollPage + 1));
            event.target.complete();
            event.target.disabled = this.visibleVehicles.length >= this.searchResultsCount;
        }, 200);
    }

    get selectedTextState() {
        if (this.searchState === '') {
            return 'Zustand';
        }
        return this.states.get(this.searchState as number).name;
    }

    get selectedTextLocation() {
        if (this.searchLocation === '') {
            return 'Standort';
        }
        if (this.searchLocation === 0) {
            return 'Standort Unbekannt';
        }
        return this.possibleLocations.find(l => l.id === this.searchLocation as number).name;
    }

    get selectedTextGroup() {
        if (this.searchGroup === '') {
            return 'Gruppe';
        }
        if (this.searchGroup === 0) {
            return 'Ohne Gruppe';
        }
        return this.possibleGroups.find(g => g.id === this.searchGroup as number).name;
    }

    startInsomnia() {
        if (!this.platform.is('cordova')) {
            reportErrorMessage('Cordova nicht verfügbar');
            return;
        }
        this.insomnia.keepAwake()
            .catch(e => this.toast.httpError(e));
    }

    stopInsomnia() {
        if (!this.platform.is('cordova')) {
            reportErrorMessage('Cordova nicht verfügbar');
            return;
        }
        this.insomnia.allowSleepAgain()
            .catch(e => this.toast.httpError(e));
    }
}
