import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {VehiclesService} from '../../../vehicles/service/vehicles/vehicles.service';
import {ToastService} from '../../../../shared/service/toast/toast.service';
import {reportError} from '../../../../shared/error/sentry.error-handler';
import {sendDelete, sendPatch, sendPost} from '../../../../shared/function/api-url.function';


@Injectable({
    providedIn: 'root'
})
export class VehicleIssueService {

    constructor(
        private http: HttpClient,
        private vehicles: VehiclesService,
        private toast: ToastService,
    ) {
    }

    async store(vehicleId, issueData) {
        const url = '/v1/fleet/vehicle/' + vehicleId + '/issue';
        const response = await sendPost(this.http, url, {
            issue: issueData
        })
            .toPromise().catch((e: HttpErrorResponse) => {
            reportError(e);
            return e;
        });
        if (!(response instanceof HttpErrorResponse)) {
            await this.toast.success('Erfolgreich gespeichert');
            await this.vehicles.loadOneFromServer(vehicleId);
            return true;
        }
        await this.toast.error(this.parseErrors(response));
        return false;
    }

    async storeBulk(vehicles, formData) {
        let errorCount = 0;
        for (const v of vehicles) {
            const result = await this.store(v.id, formData);
            if (!result) {
                errorCount ++;
            }
        }
        if (errorCount > 0) {
            await this.toast.error(errorCount + ' von ' + vehicles.length + ' Aufträgen konnten nicht abgespeichert werden');
            return false;
        }
        return true;
    }

    async update(vehicleIssueId, vehicleId, issueData) {
        const url = '/v1/fleet/vehicle/issue/' + vehicleIssueId;
        const response = await sendPatch(this.http, url, {issue: issueData}).toPromise().catch((e: HttpErrorResponse) => {
            reportError(e);
            if (e.status >= 500) {
                this.toast.httpError(e);
                this.vehicles.loadOneFromServer(vehicleId);
            }
            return e;
        });
        if (!(response instanceof HttpErrorResponse)) {
            await this.vehicles.loadOneFromServer(vehicleId);
            await this.toast.success('Erfolgreich gespeichert');
            return true;
        }
        await this.toast.error(this.parseErrors(response));
        return false;
    }

    async delete(vehicleIssueId, vehicleId) {
        const url = '/v1/fleet/vehicle/issue/' + vehicleIssueId;
        const response = await sendDelete(this.http, url).toPromise();
        if (!(response instanceof HttpErrorResponse)) {
            this.vehicles.set(await this.vehicles.loadOneFromServer(vehicleId));
        }
    }

    parseErrors(e: HttpErrorResponse): string {
        const errors = e.error.errors;
        let message = '';

        errors.map((error) => {
            const tempString = 'Feld' + error.field + ': ' + error.message;
            message += tempString + '<br>';
        });
        return message;
    }
}
