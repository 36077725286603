import {Component} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {VehiclesService} from '../../service/vehicles/vehicles.service';
import {LoadingService} from '../../../../shared/service/loading/loading.service';
import {StateService} from '../../service/state/state.service';
import {AlertsService} from 'nb-form';
import {Location} from '@angular/common';
import {VehicleSimpleComponent} from '../vehicle-simple/vehicle-simple.component';
import {VehicleModalComponent} from './vehicle-modal/vehicle-modal';
import {ModalController} from '@ionic/angular';
import {ProtocolService} from '../../../protocol/service/protocol.service';
import {StressTestService} from '../../../protocol/service/stress-test.service';

@Component({
    selector: 'app-vehicle',
    templateUrl: './vehicle.component.html',
    styleUrls: ['./vehicle.component.scss'],
})
export class VehicleComponent extends VehicleSimpleComponent {

    constructor(
        route: ActivatedRoute,
        vehicles: VehiclesService,
        states: StateService,
        loading: LoadingService,
        location: Location,
        protocols: ProtocolService,
        alert: AlertsService,
        router: Router,
        private modalCtrl: ModalController
    ) {
        super(route, vehicles, states, loading, location, protocols, alert, router);
    }

    async editVehicle() {
        const modalRef = await this.modalCtrl.create({
            component: VehicleModalComponent,
            cssClass: 'modal-large',
            componentProps: {
                title: 'Fahrzeug bearbeiten',
                vehicle: this.vehicle
            }
        });
        await modalRef.present();
    }
}
